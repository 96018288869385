import Vue from 'vue';
import Router from 'vue-router';
// import { i18n } from './i18n';
import store from './store/index';

// Components
import Dashboard from './components/Dashboard.vue';
import ActivityLog from './components/ActivityLog.vue';
import Login from './components/Login.vue';
import ResetPassword from './components/ResetPassword.vue';

// -- Users
import UsersList from './components/users/UsersList.vue';
import UsersStore from './components/users/UsersStore.vue';

// -- Account
import AccountEdit from './components/AccountEdit.vue';

// -- Products
import ProductsList from './components/ProductsList.vue';
import ProductEdit from './components/ProductEdit.vue';
import ProductsImport from './components/ProductsImport.vue';

// -- Dicionaries
import DictionariesList from './components/dictionaries/DictionariesList.vue';
import DictionariesBrands from './components/dictionaries/Brands.vue';
import DictionariesAttributes from './components/dictionaries/Attributes.vue';
import DictionariesCategories from './components/dictionaries/Categories.vue';
import DictionariesCategoriesAltum from './components/dictionaries/CategoriesAltum.vue';
import DictionariesStyles from './components/dictionaries/Styles.vue';
import DictionariesStylesAltum from './components/dictionaries/StylesAltum.vue';

import DictionariesCollections from './components/dictionaries/Collections.vue';
import DictionariesMaterials from './components/dictionaries/Materials.vue';
import DictionariesColors from './components/dictionaries/Colors.vue';
import DictionariesRooms from './components/dictionaries/Rooms.vue';
import DictionariesRoomsAltum from './components/dictionaries/RoomsAltum.vue';

import DictionariesProducers from './components/dictionaries/Producers.vue';
import DictionariesCountries from './components/dictionaries/Countries.vue';
import DictionariesSeasons from './components/dictionaries/Seasons.vue';
import DictionariesCodeKinds from './components/dictionaries/CodeKinds.vue';
import DictionariesCodeTypes from './components/dictionaries/CodeTypes.vue';
import DictionariesErpCategories from './components/dictionaries/ErpCategories.vue';
import DictionariesTypes from './components/dictionaries/Types.vue';
import DictionariesVats from './components/dictionaries/Vats.vue';
import DictionariesShipmentSizes from './components/dictionaries/ShipmentSizes.vue';

// -- Files
import Files from './components/Files.vue';
import FilesUpload from './components/FilesUpload.vue';
import Shops from './components/Shops.vue';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [{
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                submenu: 'dashboard'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/reset-password',
            name: 'resetPassword',
            component: ResetPassword
        },
        {
            path: '/activity-log',
            name: 'activityLog',
            component: ActivityLog,
            meta: {
                submenu: 'activity'
            }
        },
        {
            path: '/users-list',
            name: 'usersList',
            component: UsersList,
            meta: {
                submenu: 'users'
            }
        },
        {
            path: '/users-store',
            name: 'usersStore',
            component: UsersStore,
            meta: {
                submenu: 'users'
            }
        },
        {
            path: '/edit-account',
            name: 'accountEdit',
            component: AccountEdit
        },
        {
            path: '/products',
            name: 'productsList',
            component: ProductsList,
            meta: {
                submenu: 'products'
            }
        },
        {
            path: '/product/:id/edit/shop/:shop',
            name: 'productEdit',
            component: ProductEdit,
            meta: {
                submenu: 'products'
            }
        },
        {
            path: '/products/import',
            name: 'productsImport',
            component: ProductsImport,
            meta: {
                submenu: 'products'
            }
        },
        {
            path: '/dictionaries',
            name: 'dictionariesList',
            component: DictionariesList,
            meta: {
                submenu: 'dictionaries',
                adminAuth: true
            },
            children: [
                {
                    path: 'product-brands',
                    name: 'dictionariesBrands',
                    component: DictionariesBrands,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'product-categories',
                    name: 'dictionariesCategories',
                    component: DictionariesCategories,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'product-categories-altum',
                    name: 'dictionariesCategoriesAltum',
                    component: DictionariesCategoriesAltum,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'rooms',
                    name: 'dictionariesRooms',
                    component: DictionariesRooms,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'rooms-altum',
                    name: 'dictionariesRoomsAltum',
                    component: DictionariesRoomsAltum,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'product-styles',
                    name: 'dictionariesStyles',
                    component: DictionariesStyles,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'product-styles-altum',
                    name: 'dictionariesStylesAltum',
                    component: DictionariesStylesAltum,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'product-collections',
                    name: 'dictionariesCollections',
                    component: DictionariesCollections,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'attributes',
                    name: 'dictionariesAttributes',
                    component: DictionariesAttributes,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'materials',
                    name: 'dictionariesMaterials',
                    component: DictionariesMaterials,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'colors',
                    name: 'dictionariesColors',
                    component: DictionariesColors,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'producers',
                    name: 'dictionariesProducers',
                    component: DictionariesProducers,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'countries',
                    name: 'dictionariesCountries',
                    component: DictionariesCountries,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'seasons',
                    name: 'dictionariesSeasons',
                    component: DictionariesSeasons,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'code-kinds',
                    name: 'dictionariesCodeKinds',
                    component: DictionariesCodeKinds,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'code-types',
                    name: 'dictionariesCodeTypes',
                    component: DictionariesCodeTypes,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'erp-categories',
                    name: 'dictionariesErpCategories',
                    component: DictionariesErpCategories,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'types',
                    name: 'dictionariesTypes',
                    component: DictionariesTypes,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'vats',
                    name: 'dictionariesVats',
                    component: DictionariesVats,
                    meta: {
                        submenu: 'dictionaries'
                    }
                },
                {
                    path: 'shipment-sizes',
                    name: 'dictionariesShipmentSizes',
                    component: DictionariesShipmentSizes,
                    meta: {
                        submenu: 'dictionaries'
                    }
                }
            ]
        },
        {
            path: '/files',
            name: 'files',
            component: Files,
            meta: {
                submenu: 'files'
            }
        },
        {
            path: '/shops',
            name: 'shops',
            component: Shops,
            meta: {
                submenu: 'shops'
            }
        },
        {
            path: '/files/upload',
            name: 'filesUpload',
            component: FilesUpload,
            meta: {
                submenu: 'files'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    // try to access a restricted page + not logged in
    const publicPages = ["/login", "/reset-password"];
    const authRequired = !publicPages.includes(to.path);
    const user = localStorage.getItem("user");

    if (authRequired && !user) {
        return next('/login');
    } else if (user) {
        if (to.meta.adminAuth) {
            let userJson = JSON.parse(user);

            if (userJson.role != "admin") {
                return;
            }
        }

        // shops
        if (
            !store.state.shop.all ||
            (store.state.shop.all && !store.state.shop.all.length)
        ) {
            store.dispatch("shop/fetchShops");
        }

        // Change shop form param
        if (
            to.params.shop &&
            store.state.shop.current &&
            to.params.shop != store.state.shop.current
        ) {
            store
                .dispatch("shop/setShop", {
                    code: to.params.shop,
                    preventReload: true,
                })
                .then(() => {
                    return next();
                });
        } else if (!store.state.shop.current) {
            store
                .dispatch("shop/setShop", {
                    code: "pl",
                    preventReload: true,
                })
                .then(() => {
                    return next();
                });
        } else {
            return next();
        }
    } else {
        return next();
    }
});