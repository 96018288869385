<template>
    <div class="dashboard">

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Pulpit</h1>
                    <div class="content-top__subheading">{{currentUser.name}}</div>
                </div>
            </div>
        </div>

        <div class="dashboard__content">
            <i>"Jeżeli zabałaganione biurko jest oznaką zabałaganionego umysłu, oznaką czego jest puste biurko?"</i>
            <br><strong>Albert Einstein</strong>
        </div>

    </div>
</template>

<script>
    export default {
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        }
    }
</script>