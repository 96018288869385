<template>
    <div v-if="product">
        <div class="product-form row">
            <div class="col-24">
                <div class="product-form__header d-flex align-items-center">
                    <div
                        :class="{ 'is-hidden': isHidden }"
                        @click.prevent="isHidden = !isHidden"
                    >
                        <icon
                            icon="plus"
                            v-if="!isHidden"
                            icon-classes="icon__plus--large icon--black icon--rotated"
                        />
                        <icon
                            icon="plus"
                            v-else
                            icon-classes="icon__plus--large icon--black"
                        />
                    </div>
                    <span class="product-form__header-title">Artykuły powiązane</span>
                </div>
            </div>

            <transition name="transition-slide">

                <div
                    class="col-24 product-form__content"
                    v-if="!isHidden && product.connect.length > 0"
                >
                    <table class="items-table">
                        <thead>
                            <tr class="items-table__tr">
                                <th class="items-table__th">SKU</th>
                                <th class="items-table__th">Name</th>
                                <th class="items-table__th">Relacja</th>
                                <th class="items-table__th"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            class="items-table__tr"
                                v-for="connectProduct in product.connect"
                                :key="connectProduct.id"
                            >
                                <td
                                    class="items-table__td"
                                >
                                    <div class="form-box__mobile-element">
                                        SKU
                                    </div>
                                    <router-link
                                        class="link"
                                        :to="{
                                            name: 'productEdit',
                                            params: {
                                                sku: connectProduct.sku,
                                            },
                                        }"
                                    >
                                        {{ connectProduct.sku }}
                                    </router-link>
                                </td>
                                <td
                                    class="items-table__td"
                                >
                                    <div class="form-box__mobile-element">
                                        Name
                                    </div>
                                    {{ connectProduct.name_short }}
                                </td>
                                <td class="items-table__td ">
                                    <div class="form-box__mobile-element">
                                        Relacja
                                    </div>
                                        <treeselect
                                            class="vue-treeselect__control"
                                            v-if="relations.length > 0"
                                            v-model="connectProduct.relation"
                                            :multiple="false"
                                            :options="relations"
                                            placeholder=""
                                            :clearable="false"
                                            :normalizer="normalizer"
                                            @input="connect(connectProduct, 'change')"
                                        />
                                </td>
                                <td
                                    class="items-table__td items-table__td--button"
                                >
                                    <div class="form-box__mobile-element">
                                        Actions
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button 
                                            class="button-icon button-icon--ml-10"
                                            @click="connect(connectProduct, 'delete')"
                                        >
                                            <icon
                                                icon="unlink"
                                                icon-classes="icon--regular"
                                            />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="d-flex justify-content-end pt-4">
                        <button
                            class="button icon__icons-wrap button--grey"
                            @click="toggleModal()"
                        >
                            <icon
                                icon="link"
                                icon-classes="button__icon button__icon--left icon--regular"
                            />
                            <span>Connect</span>
                        </button>
                    </div>
                </div>
                <div v-else class="col-24 product-form__content">
                    <div class="d-flex justify-content-start">
                        <table class="items-table">
                            <tr class="items-table__tr">
                                <td class="items-table__td">
                                    <span class="items-table__noitems"
                                        >No data.</span
                                    >
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="d-flex justify-content-end pt-4">
                        <button
                            class="button button--icon button--grey"
                            @click="toggleModal()"
                        >
                            <icon
                                icon="link"
                                icon-classes="button__icon button__icon--left icon--regular"
                            />
                            <span>Connect</span>
                        </button>
                    </div>
                </div>
            </transition>
        </div>

        <!-- Modal -->
        <modal :show="showModal" @close="showModal = !showModal">
            <div
                slot="header"
                class="d-flex flex-column flex-xl-row justify-content-xl-between align-items-xl-center w-100"
            >
                <div
                    class="d-flex flex-column justify-content-center flex-lg-row justify-content-lg-between"
                >
                    <div class="simple-search simple-search--modal">
                        <input
                            type="text"
                            class="simple-search__input"
                            style="min-width: 80px"
                            @input="searchProducts()"
                            placeholder="Search..."
                            v-model="search"
                        />
                        <button
                            class="simple-search__button"
                            @click="getProducts()"
                        >
                            <icon icon="search" />
                        </button>
                    </div>
                </div>
            </div>

            <div slot="body">
                <div v-if="!loadingProducts">
                    <table
                        class="items-table"
                        v-if="connectProducts.data && connectProducts.data.length > 0"
                    >
                        <thead>
                            <tr class="items-table__tr">
                                <th class="items-table__th">SKU</th>
                                <th
                                    class="items-table__th"
                                >
                                    Name
                                </th>
                                <th class="items-table__th">Relacja</th>
                                <th class="items-table__th pr-0 text-right">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="items-table__tr"
                                v-for="connectProduct in connectProducts.data"
                                :key="connectProduct.id"
                            >
                                <td
                                    class="items-table__td items-table__td--nowrap"
                                >
                                    <div class="form-box__mobile-element">
                                        SKU
                                    </div>
                                    {{ connectProduct.sku }}
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">
                                        Name
                                    </div>
                                    {{ connectProduct.name_short }}
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">
                                        Relacja
                                    </div>
                                    <treeselect
                                        class="vue-treeselect__control"
                                        v-if="relations.length > 0"
                                        v-model="connectProduct.relation"
                                        :multiple="false"
                                        :options="relations"
                                        placeholder=""
                                        :clearable="false"
                                        :normalizer="normalizer"
                                    />
                                </td>
                                <td
                                    class="items-table__td items-table__td--button"
                                >
                                    <div class="form-box__mobile-element">
                                        Actions
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button
                                            class="button-icon"
                                            v-if="
                                                connectedProductsIds.includes(
                                                    connectProduct.id
                                                )
                                            "
                                            @click="connect(connectProduct, 'delete')"
                                        >
                                            <icon icon="unlink" />
                                        </button>
                                        <button
                                            class="button-icon"
                                            v-else
                                            @click="connect(connectProduct, 'add')"
                                        >
                                            <icon icon="link" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-else>
                        No connectProducts in database.
                    </div>

                    <Pagination
                        :pagination="connectProducts"
                        @paginate="getProducts()"
                        :offset="3"
                    ></Pagination>
                </div>
                <div v-else class="d-flex align-items-center">
                    <div
                        class="loader button__icon button__icon--left d-inline-block"
                    ></div>
                    <div>Loading...</div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="buttonconnectedProducts button--small button--grey"
                    @click="showModal = false"
                >
                    Cancel
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "./../parts/Modal";
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import _ from "lodash";
import Icon from "../parts/Icon";
import Pagination from "./../parts/Pagination";
import Treeselect from '@riophae/vue-treeselect';

export default {
    data() {
        return {
            isHidden: false,
            showModal: false,
            products: {
                loading: true,
            },
            connectProducts: [],
            loadingProducts: true,
            connectedProductsIds: [],
            search: "",
            order: "desc",
            orderBy: "created_at",
            relations: [
                {
                    id: 'Sugerowany do sprzedaży',
                    label: 'Sugerowany do sprzedaży'
                },{
                    id: 'Akcesorium',
                    label: 'Akcesorium'
                },{
                    id: 'Zamiennik',
                    label: 'Zamiennik'
                },{
                    id: 'Towar nadrzędny',
                    label: 'Towar nadrzędny'
                }
            ],
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.label,
                }
            },
        };
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    components: {
        Modal,
        Pagination,
        Icon,
        Treeselect
    },
    methods: {
        async getProducts() {
            this.loadingProducts = true;

            this.connectedProductsIds = [];
            this.product.connect.forEach((product) => {
                this.connectedProductsIds.push(product.id);
            });

            try {
                var params = {
                    page: this.connectProducts.current_page,
                    order_by: this.orderBy,
                    order: this.order,
                    per_page: 30,
                    exclude: [this.product.id, ...this.connectedProductsIds],
                    lang: this.$store.state.shop.current,
                };
                if (this.search != "") params.search = this.search;

                const response = await axios.get(API_BASE_URL + "/products", {
                    params: params,
                });
                this.connectProducts = response.data;

                this.loadingProducts = false;
            } catch (error) {
                this.loadingProducts = false;
                this.$root.$emit("noticesBox", error.response.data);
            }
        },
        searchProducts: _.debounce(function() {
            this.connectProducts.current_page = 1;
            this.getProducts();
        }, 400),
        toggleModal() {
            if (!this.connectProducts.length) {
                this.getProducts();
            }
            this.showModal = !this.showModal;
        },
        async connect(connectProduct, action = "add") {
            // TODO - zapis na przycisk, a nie po zmianie
            if(connectProduct.relation==undefined){
                this.$root.$emit("noticesBox", {
                    type: "default",
                    message: "Podaj rodzaj relacji."
                });
                return;
            }
            try {
                const response = await axios.post(
                    API_BASE_URL + `/products/${this.product.id}/connect`,
                    {
                        connect_product_id: connectProduct.id,
                        action: action,
                        relation: connectProduct.relation,
                    }
                );
                this.product.connect = response.data.product.connect;

                this.connectedProductsIds = [];
                this.product.connect.forEach((connectProduct) => {
                    this.connectedProductsIds.push(connectProduct.id);
                });

                this.$root.$emit("noticesBox", {
                    type: "success",
                    message: response.data.message,
                });
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
            }
        },
    },
};
</script>
