<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-shipment-sizes"
        title = "Lista wielkości przesyłek"
        :numeral_text = "['wielkość przesyłek', 'wielkości przesyłek', 'wielkości przesyłek']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>