<template>
    <div class="mb-3">
        <div class='product-form__title'>Galeria</div>
        <input
            type="file"
            ref="photosInput"
            @change="onImageChange"
            :disabled="isStoring || isChangingDefault"
            style="display: none;"
        />

        <draggable
            v-model="product.photos"
            @end="reOrdered()"
            class="product-photos"
            v-if="product.photos && product.photos.length"
        >
            <div
                v-for="photo in product.photos"
                :key="'photo_' + photo.id"
                class="product-photo"
                :class="{ 'is-changing': isChangingOrder }"
            >
                <div class="product-photo__container">
                    <img
                        :src="getApiPath(photo.path)"
                        class="product-photo__img"
                    />

                    <button
                        class="button-icon button-icon--ml-10 button-icon--bg-white product-photo__unlink"
                        :disabled="isStoring || isChangingDefault"
                        @click="deleteObject(photo)"
                    >
                        <icon icon="unlink" />
                    </button>
                </div>
                <div class="product-photo__actions d-flex align-items-center justify-content-end">
                    <input v-model="photo.alt" class="product-form__input product-photo__alt-input" @change="setPhotoAlt(photo)" />
                </div>
            </div>
        </draggable>
        <div class="col-24" v-else>Nie dodano jeszcez zdjęcia do galerii</div>
        <div class="d-flex mt-3">
            <button
                class="button button--icon"
                @click="$refs.photosInput.click()"
                :disabled="isStoring || isChangingDefault"
            >
                <icon
                    v-if="!isStoring"
                    icon="plus"
                    icon-classes="icon--white button__icon button__icon--left icon--white"
                ></icon>
                <div
                    v-else
                    class="loader button__icon button__icon--left"
                ></div>
                <span
                    class="button__text"
                    v-text="isStoring ? 'Wysyłanie...' : 'Dodaj zdjęcie'"
                >
                    Dodaj zdjęcie</span
                >
            </button>
        </div>

        <modal :show="showModal" size="md" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie zdjęcia
            </div>

            <div slot="body">
                Czy chcesz usunąć to zdjęcie?
                <div class="product-photo mt-4">
                    <img :src="getApiPath(objectToDelete.path)" />
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="button button--small button--grey"
                    @click="showModal = false"
                >
                    Nie
                </button>
                <button
                    class="button button--small button--ml-10"
                    @click="confirmdeleteObject()"
                >
                    Tak
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Icon from "../parts/Icon";
import Modal from "../parts/Modal";
import draggable from "vuedraggable";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;

export default {
    data() {
        return {
            image: "",
            imagePath: "",
            isStoring: false,
            isChangingDefault: false,
            isChangingOrder: false,
            showModal: false,
            defaultSelected: false,
            objectToDelete: false,
        };
    },
    components: {
        Icon,
        Modal,
        draggable,
    },
    props: {
        product: {
            type: Object,
            required: false
        }
    },
    methods: {
        savePhoto() {
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            let vm = this;
            let formData = new FormData();

            vm.isStoring = true;

            formData.append("image", this.image);
            formData.append("product_id", this.product.id);
            formData.append("type", "gallery");
            formData.append("locale", this.$store.state.shop.current);
            vm.$emit("storingProduct", true);

            axios
                .post(API_BASE_URL + "/products/photos", formData, config)
                .then(function(response) {

                    vm.$emit("storingProduct", false);
                    vm.product.photos = _.cloneDeep(response.data.photos);
                    vm.isStoring = false;

                    vm.$root.$emit("noticesBox", {
                        type: "success",
                        message: "Image saved successfully.",
                    });
                    vm.$emit('lastSynchronisedProduct');
                    vm.$emit('getWooStatusess');

                })
                .catch(function(error) {
                    vm.$emit("storingProduct", false);
                    vm.isStoring = false;
                    vm.$root.$emit("noticesBox", error.response.data);
                });

            this.$refs.photosInput.value = "";
        },
        onImageChange(e) {
            this.image = e.target.files[0];
            this.savePhoto();
        },
        getApiPath(path) {
            if (path) {
                let timestamp = new Date().getTime();

                return API_PHOTO_URL + path + "?" + timestamp;
            } else return require("../../img/default.png");
        },
        setDefaultPhoto(photoId) {
            let vm = this;
            vm.isChangingDefault = true;
            axios
                .post(API_BASE_URL + "/products/photos/default", {
                    product_id: vm.product.id,
                    product_photo_id: photoId,
                })
                .then(function() {
                    vm.$emit("storingProduct", false);
                    vm.isChangingDefault = false;

                    vm.$root.$emit("noticesBox", {
                        type: "success",
                        message: "Main image changed.",
                    });
                })
                .catch(function(error) {
                    vm.$emit("storingProduct", false);
                    vm.isChangingDefault = false;
                    vm.$root.$emit("noticesBox", error.response.data);
                });
        },
        setPhotoAlt(photo) {
            console.log(photo);
            let vm = this;
            axios
                .put(API_BASE_URL + "/products/photos/alt", {
                    id: photo.id,
                    alt: photo.alt,
                    locale: this.$store.state.shop.current
                })
                .then(() => {
                    vm.$root.$emit("noticesBox", {
                        type: "success",
                        message: "Alt changed."
                    })

                    vm.$emit('lastSynchronisedProduct');
                    vm.$emit('getWooStatusess');
                })
                .catch(function (error) {
                    vm.$emit("storingProduct", false);
                    vm.isChangingDefault = false;
                    vm.$root.$emit("noticesBox", error.response.data);
                });
        },
        async deleteObject(object) {
            this.showModal = true;
            this.objectToDelete = object;
        },
        async confirmdeleteObject() {
            let vm = this;
            this.showModal = false;
            await axios.delete(API_BASE_URL + "/products/photos/delete/" + this.objectToDelete.relation_id).then(function (response) {
                vm.product.photos = _.cloneDeep(response.data.photos);
                vm.$root.$emit("noticesBox", {
                    type: "success",
                    message: "Image removed.",
                });
                vm.$emit('lastSynchronisedProduct');
                vm.$emit('getWooStatusess');

            })
            .catch(function (error) {
                this.$root.$emit("noticesBox", error.response.data);
            });
        },
        reOrdered() {
            let vm = this;
            vm.isChangingOrder = true;
            let photosOrder = [];
            this.product.photos.forEach(function(element, index) {
                photosOrder.push({ id: element.relation_id, order: index + 1 });
            });

            axios
                .post(API_BASE_URL + "/products/photos/order", {
                    product_id: vm.product.id,
                    order: photosOrder,
                })
                .then(function() {
                    vm.$emit("storingProduct", false);
                    vm.isChangingOrder = false;

                    vm.$root.$emit("noticesBox", {
                        type: "success",
                        message: "Images order changed.",
                    });
                    vm.$emit('lastSynchronisedProduct');
                    vm.$emit('getWooStatusess');
                })
                .catch(function(error) {
                    vm.$emit("storingProduct", false);
                    vm.isChangingOrder = false;
                    vm.$root.$emit("noticesBox", error.response.data);
                });
        },
    },
};
</script>
