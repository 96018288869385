<template>
    <div class="product-form product-form--ecommerce row">
        <div v-if="isLoaded" class="w-100">
            <transition name="transition-slide">
                <div class="col-24" v-if="!isHidden">
                    <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Klasa logistyczna z EMPIK
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.logistic_class"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Zezwól na zamówienie oczekujące
                                    </div>
                                    <treeselect
                                        class="vue-treeselect"
                                        v-if="allowBackorder.length > 0"
                                        v-model="product.allow_backorder"
                                        :multiple="false"
                                        :options="allowBackorder"
                                        placeholder=""
                                        :value-consists-of="'LEAF_PRIORITY'"
                                        :default-expand-level="Infinity"
                                    />
                                    <div
                                        v-else
                                        class="product-form__note"
                                        v-text="
                                            isLoading
                                                ? 'Loading...'
                                                : allowBackorder
                                                ? 'No defined net units in database.'
                                                : 'You need to add net unit first.'
                                        "
                                    ></div>
                                </div>
                            </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Zarządzane stanem magazynowym
                                </div>
                                <label
                                    :for="
                                        'check-is_managed_inventory-' +
                                            product.id
                                    "
                                    class="product-form__checkbox"
                                >
                                    <input
                                        :id="
                                            'check-is_managed_inventory-' +
                                                product.id
                                        "
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="product.is_managed_inventory"
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            product.is_managed_inventory
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Konsimo gmc feed label
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.konsimo_gmc_feed_label"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    EAN
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.ean"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Identifier exists
                                </div>
                                <label
                                    :for="
                                        'check-is_identifier_exists-' +
                                            product.id
                                    "
                                    class="product-form__checkbox"
                                >
                                    <input
                                        :id="
                                            'check-is_identifier_exists-' +
                                                product.id
                                        "
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="product.is_identifier_exists"
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            product.is_identifier_exists
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Ceneo avil
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.ceneo_avil"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Mediaport logistic
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.media_port_logistic"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Mediaport marketplace minimal price
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.mediaport_marketplace_minimal_price"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Ilość w magazynie
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.store_quantity"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Minimalna ilość w magazynie (?)
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.store_quantity_min"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Sprzedawane osobno (?)
                                </div>
                                <label
                                    :for="
                                        'check-is_sold_individually-' +
                                            product.id
                                    "
                                    class="product-form__checkbox"
                                >
                                    <input
                                        :id="
                                            'check-is_sold_individually-' +
                                                product.id
                                        "
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="product.is_sold_individually"
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            product.is_sold_individually
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <other-sizes
                    :product="product"
                    >
                    </other-sizes>
                </div>
            </transition>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>
<script>
    import OtherSizes from './OtherSizes.vue';
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                isHidden: false,
                isLoaded: true,
                isLoading: true,
                allowBackorder: [
                    {
                        id: 'Nie zezwalaj',
                        label: 'Nie zezwalaj'
                    },
                    {
                        id: 'Pozwalaj, ale poinformuj klienta',
                        label: 'Pozwalaj, ale poinformuj klienta'
                    },
                    {
                        id: 'Zezwól',
                        label: 'Zezwól'
                    }
                ]

            }
        },
        components: {
            OtherSizes,
            Treeselect
        },
        props: {
            product: {
                type: Object,
                required: false
            }
        },

        mounted() {
            this.$root.$on('saveProduct', () => {
                this.storeProduct();
            });
        },
        beforeDestroy() {
            this.$root.$off('saveProduct');
        },
    }
</script>