<template>
    <div class="submenu d-flex justify-content-between align-items-center" :class="{ 'is-opened' : isOpened }">
        <div class="submenu__wrap">
            <div class="submenu__content">
                <ul class="submenu__items">

                    <template v-if="$route.meta.submenu == 'files'">
                        <li class="submenu__item">
                            <router-link class="submenu__link" :class="{'is-current' : $route.name == 'files'}" @click.native="isOpened = !isOpened"
                                :to="{name: 'files'}">Lista plików
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" :class="{'is-current' : $route.name == 'filesUpload'}" @click.native="isOpened = !isOpened"
                                :to="{name: 'filesUpload'}">Dodaj pliki
                            </router-link>
                        </li>
                    </template>

                    <template v-if="$route.meta.submenu == 'products'">
                        <li class="submenu__item">
                            <router-link class="submenu__link" :class="{'is-current' : $route.name == 'productsList'}" @click.native="isOpened = !isOpened"
                                :to="{name: 'productsList'}">Lista produktów
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <a @click.prevent="$root.$emit('addProductModal', {})" class="submenu__link">Dodaj produkt</a>
                        </li>
                        <!-- <li class="submenu__item">
                            <router-link class="submenu__link" :class="{'is-current' : $route.name == 'productsImport'}" @click.native="isOpened = !isOpened"
                                :to="{name: 'productsImport'}">Import
                            </router-link>
                        </li> -->
                    </template>

                    <template v-if="$route.meta.submenu == 'dictionaries'">
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesBrands'}"
                                :to="{name: 'dictionariesBrands'}">Brandy
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesCategories'}"
                                :to="{name: 'dictionariesCategories'}">Kategorie Woo
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesCategoriesAltum'}"
                                :to="{name: 'dictionariesCategoriesAltum'}">Kategorie Altum
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesRooms'}"
                                :to="{name: 'dictionariesRooms'}">Pokoje Woo
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesRoomsAltum'}"
                                :to="{name: 'dictionariesRoomsAltum'}">Pokoje Altum
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesStyles'}"
                                :to="{name: 'dictionariesStyles'}">Style Woo
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesStylesAltum'}"
                                :to="{name: 'dictionariesStylesAltum'}">Style Altum
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesCollections'}"
                                :to="{name: 'dictionariesCollections'}">Kolekcje
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesAttributes'}"
                                :to="{name: 'dictionariesAttributes'}">Atrybuty
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesMaterials'}"
                                :to="{name: 'dictionariesMaterials'}">Materiały
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesColors'}"
                                :to="{name: 'dictionariesColors'}">Kolory
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesProducers'}"
                                :to="{name: 'dictionariesProducers'}">Producenci
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesCountries'}"
                                :to="{name: 'dictionariesCountries'}">Kraje
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesSeasons'}"
                                :to="{name: 'dictionariesSeasons'}">Sezon
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesCodeKinds'}"
                                :to="{name: 'dictionariesCodeKinds'}">Rodzaj kodu
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesCodeTypes'}"
                                :to="{name: 'dictionariesCodeTypes'}">Typ kodu
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesErpCategories'}"
                                :to="{name: 'dictionariesErpCategories'}">Kategoria ERP
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesTypes'}"
                                :to="{name: 'dictionariesTypes'}">Typ produktu
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesVats'}"
                                :to="{name: 'dictionariesVats'}">Stawka vat
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'dictionariesShipmentSizes'}"
                                :to="{name: 'dictionariesShipmentSizes'}">Wielkość przesyłki
                            </router-link>
                        </li>
                    </template>

                    <template v-if="$route.meta.submenu == 'activity'">
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'activityLog'}"
                                :to="{name: 'activityLog'}">Lista aktywności użytkowników
                            </router-link>
                        </li>
                    </template>

                    <template v-if="$route.meta.submenu == 'users'">
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'usersList'}"
                                :to="{name: 'usersList'}">Lista użytkowników
                            </router-link>
                        </li>
                        <li class="submenu__item">
                            <router-link class="submenu__link" @click.native="isOpened = !isOpened"
                                :class="{'is-current' : $route.name == 'usersStore'}"
                                :to="{name: 'usersStore'}">Dodaj użytkownika
                            </router-link>
                        </li>
                    </template>

                </ul>

                <div class="submenu__mobile-close" @click="isOpened = !isOpened">
                    <icon icon="plus" icon-classes="submenu__mobile-close-icon"/>
                </div>

                <div class="submenu__shop-switcher">
                    <shop-switcher :key="$route.params.id"  v-if="hideShopSwitcher.indexOf($route.name) == -1"></shop-switcher>
                    <img class="submenu__logo ml-4" src="../../img/brand.svg" />
                </div>

            </div>

        </div>

        <!--mobile-->
        <div class="submenu__mobile">
            <img class="submenu__logo" src="../../img/brand.svg" />
        </div>
        <div class="submenu__mobile">
            <div class="mobile-menu__burger mobile-menu__burger--submenu" :class='{"close":isOpened}' @click="isOpened = !isOpened">
                <div class="mobile-menu__burger-line mobile-menu__burger-line--submenu"></div>
                <div class="mobile-menu__burger-line mobile-menu__burger-line--submenu"></div>
                <div class="mobile-menu__burger-line mobile-menu__burger-line--submenu"></div>
            </div>
        </div>
        <!--mobile-->

    </div>
</template>


<script>
    import ShopSwitcher from '@/components/parts/ShopSwitcher';
    import Icon from '@/components/parts/Icon';

    export default {
        data: () => ({
            hideShopSwitcher: [
                'productEdit',
                'shops',
                'activityLog',
                'usersList',
                'usersStore',
                'accountEdit',
                'dictionariesBrands',
                'dictionariesCollections',
                'dictionariesProducers',
                'dictionariesCountries',
                'dictionariesSeasons',
                'dictionariesCodeKinds',
                'dictionariesCodeTypes',
                'dictionariesErpCategories',
                'dictionariesTypes',
                'dictionariesVats',
                'dictionariesShipmentSizes',
            ],
            isOpened: false
        }),
        components: {
            ShopSwitcher,
            Icon
        },
    }
</script>