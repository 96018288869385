<template>

    <div class="files-list">

        <div class="content-top row">
            <div class="col-24 content-top__wrap">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista plików</h1>
                    <div class="content-top__subheading">{{ files.total }}
                        {{ files.total | numeralsText('plik','pliki','plików')}}</div>
                </div>

                <div class="content-top__search simple-search">
                    <input type="text" class="simple-search__input" @input="searchFiles()" placeholder="Wyszukaj..."
                        v-model="search">
                    <button class="simple-search__button" @click="getFiles()">
                        <icon icon="search" />
                    </button>
                </div>
            </div>

            <div v-if="files.data.length > 0" class="col-24 d-flex content-top__icons-wrap">
                <button class="button-icon button-icon--medium button-icon--red" @click.prevent="massDelete()"
                    :disabled="selectedFiles < 1">
                    <icon icon="trash" icon-classes="icon--grey" />
                </button>
            </div>
        </div>
        <table class="items-table" v-if="files.data.length > 0">

            <thead class="items-table__thead">
                <tr class="items-table__tr">
                    <th class="items-table__th">
                        <label for="check-product-toggle" class="checkbox">
                            <input id="check-product-toggle" class="checkbox__input" type="checkbox"
                                v-model="toggleSelectFiles"/>
                            <span class="checkbox__checkmark"></span>
                        </label>
                    </th>
                    <th class="items-table__th"></th>
                    <th class="items-table__th" style="min-width: 300px;">
                        <div @click.prevent="sortObjects()" class="items-table__sort"
                            :class="{'is-active' : orderBy == 'original_name'}" order-by="original_name" order="desc">
                            Nazwa
                            <icon icon="sort" icon-classes="items-table__sort-icon" />
                        </div>
                    </th>
                    <th class="items-table__th">
                        Opis
                    </th>
                    <th class="items-table__th" style="min-width: 250px;">
                        Typ
                    </th>
                    <th class="items-table__th pr-0 text-right">akcje</th>
                </tr>
            </thead>

            <tbody class="items-table__tbody">

                <!-- item -->
                <template v-for="file in files.data">
                    <tr class="items-table__tr" v-bind:key="file.id">

                        <td class="items-table__td items-table__td--not-text d-none d-xl-table-cell">
                            <label :for="'check-file-' + file.id" class="checkbox">
                                <input :id="'check-file-' + file.id" class="checkbox__input" type="checkbox"
                                    :value="file.id" v-model="selectedFiles" @keypress.enter.prevent="saveObject()"/>
                                <span class="checkbox__checkmark"></span>
                            </label>
                        </td>

                        <td class="items-table__td d-none d-xl-table-cell">
                            <!-- <img v-if="(['png','jpg','jpeg', 'gif'].indexOf(getType(file.path)) > -1)" :src="baseLink + file.path" class="form-box__image"> -->

                            <div class="items-table__icon"
                                :style="{ backgroundImage: 'url(' + getApiPath(file.type.icon) + ')'}"></div>
                        </td>

                        <td class="items-table__td">
                            <div class="form-box__mobile-element">Nazwa</div>
                            <div class="form-box__content">
                                {{ file.original_name }}
                            </div>
                        </td>

                        <td class="items-table__td items-table__td--expand">
                            <div class="form-box__mobile-element">Opis</div>
                            <div class="form-box" v-if="editingObject.key == file.id">
                                <input class="form-box__input" type="text" v-model="editingObject.description" @keypress.enter.prevent="saveObject()"/>
                            </div>
                            <div class="form-box__content" v-else>
                                {{ file.description }}
                            </div>
                        </td>

                        <td class="items-table__td items-table__td--nowrap">
                            <div class="form-box__mobile-element">Typ</div>
                            <div class="form-box__content">
                                {{ file.type }}
                            </div>
                        </td>

                        <td class="items-table__td pr-0">
                            <div class="form-box__buttons">
                                <!-- Buttons -->
                                <button v-if="editingObject.key == file.id" class="button-icon"
                                    @click.prevent="saveObject()">
                                    <icon icon="save" icon-classes="icon--grey" />
                                </button>
                                <button v-else class="button-icon" @click.prevent="editObject(file)">
                                    <icon icon="edit" icon-classes="icon--grey" />
                                </button>

                                <a
                                    v-if="getApiFilePath(file.path)"
                                    target="_blank"
                                    class="button-icon  button-icon--ml-10"
                                    :href="getApiFilePath(file.path)"
                                    >
                                    <icon icon="preview" icon-classes="icon--grey" />
                                </a>

                                <button class="button-icon button-icon--ml-10" @click="deleteFile(file)">
                                    <icon icon="trash" icon-classes="icon--grey" />
                                </button>
                                <!-- Buttons -->
                            </div>
                            <div class="form-box__mobile-element">
                                <label :for="'check-file-' + file.id" class="checkbox">
                                    <input :id="'check-file-' + file.id" class="checkbox__input" type="checkbox"
                                        :value="file.id" v-model="selectedFiles" />
                                    <span class="checkbox__checkmark"></span>
                                </label>
                            </div>
                        </td>

                    </tr>
                </template>
                <!-- end of item -->
            </tbody>
        </table>
        <div v-else class="content">
            Brak wyników.
        </div>

        <!-- Paginations -->
        <pagination :pagination="files" @paginate="getFiles()" :offset="1"></pagination>
        <!-- Paginations -->

        <!-- Modal mass delete files -->
        <modal :show="showDeleteModal" size="md" @close="showDeleteModal = !showDeleteModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie pliku
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć plik <strong>{{ objectToDelete.original_name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteFile()">
                    Tak
                </button>
            </div>
        </modal>

        <!-- Modal mass delete files -->
        <modal :show="showMassDeleteModal" size="md"
            @close="showMassDeleteModal = !showMassDeleteModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie plików
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć
                    {{ selectedFiles.length | numeralsText('zaznaczony','zaznaczone','zaznaczone')}}
                    <strong>{{ selectedFiles.length }}</strong>
                    {{ selectedFiles.length | numeralsText('plik','pliki','plików')}} ?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showMassDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmMassDelete()">
                    Tak
                </button>
            </div>
        </modal>

    </div><!-- // Files list-->

</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;

    import _ from 'lodash';
    import Icon from './parts/Icon';
    import Modal from './parts/Modal';
    import Pagination from './parts/Pagination';

    export default {
        data() {
            return {
                baseLink: API_PHOTO_URL,
                isLoading: true,
                files: {
                    total: 0,
                    per_page: 2,
                    from: 1,
                    to: 0,
                    current_page: 1,
                    data: {}
                },
                message: '',
                selectedFiles: [],
                search: '',
                order: 'asc',
                orderBy: 'original_name',
                editingObject: {},
                objectToDelete: {},
                showMassDeleteModal: false,
                showDeleteModal: false,
            }
        },
        components: {
            Pagination,
            Icon,
            Modal,
        },
        mounted() {
            this.getFiles();
        },
        computed: {
            toggleSelectFiles: {
                get: function () {
                    return this.files.data ? this.selectedFiles.length == this.files.data.length : false;
                },
                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.files.data.forEach(function (file) {
                            selected.push(file.id);
                        });
                    }

                    this.selectedFiles = selected;
                }
            }
        },
        methods: {
            getApiFilePath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + "?" + timestamp;
                } else return false;
            },
            async getFiles() {
                this.selectedFiles = [];
                try {
                    var params = {
                        'page': this.files.current_page,
                        'orderBy': this.orderBy,
                        'order': this.order,
                        'shop_code': this.$i18n.locale
                    };

                    if (this.search != '') params.search = this.search;

                    const response = await axios.get(API_BASE_URL + '/files', {
                        params: params
                    });
                    this.files = response.data;
                    this.isLoading = false;
                } catch (e) {
                    this.isLoading = false;
                    this.message = e.response.data.message;
                }
            },
            deleteFile(file) {
                this.showDeleteModal = true;
                this.objectToDelete = file;
            },
            async confirmDeleteFile() {
                this.showDeleteModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/files/' + this.objectToDelete.id);
                    this.getFiles();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            sortObjects() {
                event.target.setAttribute('order', ((this.order == 'asc') ? 'desc' : 'asc'));
                this.orderBy = event.target.getAttribute('order-by');
                this.order = event.target.getAttribute('order');
                this.getFiles();
            },
            searchFiles: _.debounce(function () {
                this.files.current_page = 1;
                this.getFiles()
            }, 400),
            async massDelete() {
                this.showMassDeleteModal = true;
            },
            async confirmMassDelete() {
                try {
                    await axios.post(API_BASE_URL + '/files/delete', {
                        files: this.selectedFiles
                    });
                    this.getFiles();
                    this.showMassDeleteModal = false;
                } catch (e) {
                    this.message = e.response.data.message;
                    this.getFiles();
                }
            },
            getApiPath(path) {
                if (path) {
                    return API_BASE_URL + '/' + path;
                } else return require('../iconfont/default-file-icon.svg');
            },
            editObject(object) {
                this.editingObject = object;
                if(!this.editingObject.type){
                    this.editingObject.type = {};
                }
                this.editingObject.key = object.id;
            },
            async saveObject() {
                try {
                    let response = await axios.post(API_BASE_URL + '/files/update', this.editingObject);
                    this.editingObject = {};
                    this.getFiles();

                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: response.data.message
                    });

                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            getType(link){
                let extension = link.substring(link.lastIndexOf('.')+1);
                return extension.toLowerCase();
            }
        }
    }
</script>