<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-brands"
        title = "Lista brandów produktów"
        :numeral_text = "['brand produktów', 'brandy produktów', 'brandów produktów']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>