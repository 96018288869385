<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-types"
        title = "Lista typów produktów"
        :numeral_text = "['typ produktów', 'typy produktów', 'typów produktów']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>