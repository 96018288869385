<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-altum-styles"
        title = "Lista styli Altum"
        :numeral_text = "['styl', 'style', 'styli']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>