<template>

    <div class="products-list">

        <div class="content-top row">
            <div class="col-24 content-top__wrap">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista produktów</h1>
                    <div class="content-top__subheading">{{ products.total }}
                        {{ products.total | numeralsText('produkt', 'produkty', 'produktów') }}</div>
                </div>

                <div class="content-top__search simple-search">

                     <div class="mr-3">
                        <button
                            class="button button--text button--icon"
                            @click.prevent="showFilters = !showFilters"
                        >
                            <icon
                                icon="filter"
                                icon-classes="button__icon button__icon--left"
                            />
                            <span
                                class="button__text"
                                v-text="
                                    showFilters
                                        ? 'Ukryj filtry'
                                        : 'Pokaż filtry'
                                "
                                >Pokaż filtry</span
                            >
                        </button>
                    </div>

                    <div class="mr-3">
                        <button
                            class="button button--text button--icon"
                            @click.prevent="showColumns = !showColumns"
                        >
                            <icon
                                icon="filter"
                                icon-classes="button__icon button__icon--left"
                            />
                            <span
                                class="button__text"
                                v-text="
                                    showColumns
                                        ? 'Ukryj kolumny'
                                        : 'Pokaż kolumny'
                                "
                                >Pokaż kolumny</span
                            >
                        </button>
                    </div>

                    <input type="text" class="simple-search__input" @input="searchProducts()" placeholder="Wyszukaj..."
                        v-model="search">
                    <button class="simple-search__button" @click="getProducts()">
                        <icon icon="search" />
                    </button>
                </div>
            </div>
        </div>
        <transition name="transition-slide">
            <div v-if="showFilters" class="filters">
                <h6 class="filters__title">Filtry</h6>

                <div class="row">
                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Dostawca
                            </div>
                            <treeselect
                                    class="vue-treeselect"
                                    v-if="producers.length > 0"
                                    v-model="filters.suppiler_ids"
                                    :multiple="true"
                                    :options="producers"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :limit = 10
                                    @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : producers
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Brand
                            </div>
                            <treeselect
                                    class="vue-treeselect"
                                    v-if="brands.length > 0"
                                    v-model="filters.brand_ids"
                                    :multiple="true"
                                    :options="brands"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :limit = 10
                                    @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : brands
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Kolekcje
                            </div>
                            <treeselect
                                    class="vue-treeselect"
                                    v-model="filters.collection_ids"
                                    :multiple="true"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :limit = 10
                                    :async="true"
                                    :load-options="loadOptions"
                                    :options="collections"
                                    :clear-on-select="true"
                                    :clearable="true"
                                    @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                                />
                        </div>
                    </div>

                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Status produktu
                            </div>
                            <treeselect
                                v-model="filters.stock_status_ids"
                                :multiple="true"
                                :options="stock_statuses"
                                placeholder=""
                                :clearable="true"
                                :normalizer="normalizer"
                                :default-expand-level="Infinity"
                                @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                            />
                        </div>
                    </div>


                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Status synchronizacji
                            </div>
                            <treeselect
                                v-model="filters.woo"
                                :multiple="false"
                                :options="wooStatuses"
                                placeholder=""
                                :clearable="true"
                                :default-expand-level="Infinity"
                                @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                            />
                        </div>
                    </div>

                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Kategorie
                            </div>
                            <treeselect
                                    class="vue-treeselect"
                                    v-if="categories.length > 0"
                                    v-model="filters.category_ids"
                                    :multiple="true"
                                    :options="categories"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :limit = 10
                                    @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : categories
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-8">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Pokoje
                            </div>
                            <treeselect
                                    class="vue-treeselect"
                                    v-if="rooms.length > 0"
                                    v-model="filters.room_ids"
                                    :multiple="true"
                                    :options="rooms"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :limit = 10
                                    @input="clearSelectedProducts(); getProducts(); addFiltersToLocalStorage()"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : rooms
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="transition-slide">
            <div v-if="showColumns" class="filters">
                <h6 class="filters__title">Kolumny</h6>

                <div class="row">
                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Kategorie produktowe
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-category"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-category"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="
                                            columns.category
                                        "
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.category
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">

                            <div class="product-form__title">
                                Kolor podstawowy
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-color_primary"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-color_primary"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="columns.color_primary"
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.color_primary
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Kolory potomne
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-colors_child"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-colors_child"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="
                                            columns.colors_child
                                        "
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.colors_child
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Status produktu
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-stock_status"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-stock_status"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="
                                            columns.stock_status
                                        "
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.stock_status
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Data modyfikacji
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-updated_at"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-updated_at"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="
                                            columns.updated_at
                                        "
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.updated_at
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Cena regularna
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-price"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-price"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="
                                            columns.price
                                        "
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.price
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-24 col-xl-6">
                        <div class="product-form__input-wrap">
                            <div class="product-form__title">
                                Atrybuty
                            </div>
                            <div class="d-flex align-items-center">
                                <label
                                    for="check-attributes"
                                    class="product-form__checkbox"
                                >
                                    <input
                                        id="check-attributes"
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="
                                            columns.attributes
                                        "
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            columns.attributes
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div v-if="products.data.length > 0" class="content content--products-list">

            <table class="items-table">
                <thead class="items-table__thead">
                    <tr class="items-table__tr">
                        <!-- <th class="items-table__th">
                            <label for="check-product-toggle" class="checkbox">
                                <input id="check-product-toggle" class="checkbox__input" type="checkbox"
                                    v-model="toggleSelectProducts" />
                                <span class="checkbox__checkmark"></span>
                            </label>
                        </th> -->
                        <th class="items-table__th" v-show="columns.sku">
                            <div @click.prevent="sortObjects()" class="items-table__sort"
                                :class="{'is-active' : orderBy == 'sku'}" order-by="sku" order="desc">
                                SKU
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th">
                            <div @click.prevent="sortObjects()" class="items-table__sort"
                                :class="{'is-active' : orderBy == 'name_short'}" order-by="name_short" order="desc">
                                Nazwa
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th">
                            <div :class="{'is-active' : orderBy == 'collection_id'}" order-by="collection_id" order="desc">
                                Kolekcja
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.category">
                            <div :class="{'is-active' : orderBy == 'category_id'}" order-by="category_id" order="desc">
                                Kategoria
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.price">
                            <div :class="{'is-active' : orderBy == 'price'}" order-by="price" order="desc">
                                Cena regularna
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.color_primary">
                            <div :class="{'is-active' : orderBy == 'color_primary.name'}" order-by="color_primary.name" order="desc">
                                Kolor Podstatwowy
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.colors_child">
                            <div :class="{'is-active' : orderBy == 'price_sale'}" order-by="price_sale" order="desc">
                                Kolory potomne
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.stock_status">
                            <div :class="{'is-active' : orderBy == 'stock_status.name'}" order-by="stock_status.anme" order="desc">
                                Status produktu
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.attributes">
                            <div :class="{'is-active' : orderBy == 'price_sale'}" order-by="price_sale" order="desc">
                                Atrybuty
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th"  style="max-width: 100px;">
                            <div class="items-table__sort">
                                Status synchronizacji
                            </div>
                        </th>
                        <th class="items-table__th" v-show="columns.updated_at">
                            <div @click.prevent="sortObjects()" class="items-table__sort"
                                :class="{'is-active' : orderBy == 'updated_at'}" order-by="updated_at" order="desc">
                                Modyfikacja
                                <icon icon="sort" icon-classes="items-table__sort-icon" />
                            </div>
                        </th>
                        <th class="items-table__th p-0">Akcje</th>
                    </tr>
                </thead>
                <tbody class="items-table__tbody">
                    <!-- item -->
                    <template v-for="product in products.data">
                        <tr class="items-table__tr" v-bind:key="product.id">

                            <!-- <td class="items-table__td items-table__td--not-text d-none d-xl-table-cell">
                                <label :for="'check-product-' + product.id" class="checkbox">
                                    <input :id="'check-product-' + product.id" class="checkbox__input" type="checkbox"
                                        :value="product.id" v-model="selectedProducts" />
                                    <span class="checkbox__checkmark"></span>
                                </label>
                            </td> -->

                            <td class="items-table__td"  v-show="columns.sku">
                                <div class="form-box__mobile-element">SKU</div>
                                <span v-if="product.sku">{{ product.sku }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="items-table__td items-table__td">
                                <div class="form-box__mobile-element">Nazwa</div>
                                <router-link class="items-table__link"  v-if="product.name_short"
                                    v-bind:to="{name: 'productEdit', params: { id: product.id, shop: $store.state.shop.current }}">{{ product.name_short }}
                                </router-link>
                                <span v-else>-</span>
                            </td>

                            <td class="items-table__td items-table__td">
                                <div class="form-box__mobile-element">Kolekcja</div>
                                <router-link class="items-table__link"
                                    v-bind:to="{name: 'productEdit', params: { id: product.id, shop: $store.state.shop.current }}">
                                    <span v-if="product.collection">{{ product.collection.name }}</span>
                                    <span v-else> - </span>
                                </router-link>
                            </td>

                            <td class="items-table__td items-table__td" v-show="columns.category">
                                <div class="form-box__mobile-element">Kategoria</div>
                                <router-link class="items-table__link"
                                    v-bind:to="{name: 'productEdit', params: { id: product.id, shop: $store.state.shop.current }}">
                                    <span v-if="product.category">{{ product.category.name }}</span>
                                    <span v-else> - </span>
                                </router-link>
                            </td>

                            <td class="items-table__td items-table__td" v-show="columns.price">
                                <div class="form-box__mobile-element">Cena regularna</div>
                                <span v-if="product.price">{{ product.price }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="items-table__td items-table__td" v-show="columns.color_primary">
                                <div class="form-box__mobile-element">Kolor podstawowy</div>
                                <span v-if="product.color_primary">{{ product.color_primary.name }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="items-table__td items-table__td" v-show="columns.colors_child">
                                <div class="form-box__mobile-element">Kolory potomne</div>
                                <span v-for="color in product.colors_child" :key="color.id">
                                    {{color.name}},
                                </span>
                                <span v-if="product.colors_child.length==0">-</span>
                            </td>

                            <td class="items-table__td items-table__td" v-show="columns.stock_status">
                                <div class="form-box__mobile-element">Status produktu</div>
                                <span v-if="product.stock_status">{{ product.stock_status.name }}</span>
                                <span v-else>-</span>
                            </td>

                            <td class="items-table__td items-table__td" v-show="columns.attributes">
                                <div class="form-box__mobile-element">Atrybuty</div>
                                <span v-for="attribute in product.attributes" :key="attribute.id">
                                    {{attribute.name}},
                                </span>
                                <span v-if="product.attributes.length==0">-</span>

                            </td>

                            <td class="items-table__td items-table__td--not-text">
                                <div class="form-box__mobile-element">Status synchronizacji</div>
                                <div :class="['items-table__status', 'items-table__status--' + wooSyncStatus(product)]"></div>
                            </td>

                            <td class="items-table__td items-table__td--nowrap" v-show="columns.updated_at">
                                <div class="form-box__mobile-element">Modyfikacja</div>
                                {{ product.updated_at | prettyDate }}
                            </td>

                            <td class="items-table__td pr-0">
                                <div class="form-box__buttons">

                                    <router-link :to="{name: 'productEdit', params: { id: product.id, shop: $store.state.shop.current }}"
                                        class="button-icon button-icon--ml-10">
                                        <icon icon="edit" icon-classes="icon--grey" />
                                    </router-link>

                                    <div class="button-icon button-icon--ml-10" @click="deleteProduct(product)">
                                        <icon icon="trash" icon-classes="icon--grey" />
                                    </div>
                                </div>
                                <div class="form-box__mobile-element">
                                    <label :for="'check-product-' + product.id" class="checkbox">
                                        <input :id="'check-product-' + product.id" class="checkbox__input" type="checkbox"
                                            :value="product.id" v-model="selectedProducts" />
                                        <span class="checkbox__checkmark"></span>
                                    </label>
                                </div>
                            </td>

                        </tr>
                    </template>
                    <!-- end of item -->
                </tbody>
            </table>

            </div><!-- // Products list-->

            <div v-else class="content">
                Brak wyników.
            </div>
        </div>

        <!-- Pagination -->
        <pagination :pagination="products" @paginate="getProducts()" :offset="1"></pagination>
        <!-- Pagination -->

        <!-- Modal delete product -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie produktu
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć produkt
                    <strong>{{ productToDelete.sku + ' - ' + productToDelete.name_short }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteProduct()">
                    Tak
                </button>
            </div>
        </modal>
    </div><!-- // Products -->

</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';
    import Icon from './parts/Icon';
    import Pagination from './parts/Pagination';
    import Modal from './parts/Modal';
    import Treeselect from "@riophae/vue-treeselect";
    import { wooSyncStatus } from "../helpers/status";
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

    export default {
        data() {
            return {
                isLoading: true,
                products: {
                    total: 0,
                    per_page: 2,
                    from: 1,
                    to: 0,
                    current_page: 1,
                    data: {}
                },
                message: '',
                shops: [],
                selectedProducts: [],
                search: '',
                order: 'desc',
                orderBy: 'created_at',
                showModal: false,
                productToDelete: {},
                isExporting: false,
                showFilters: false,
                showColumns: false,
                columns: {
                    sku: true,
                    updated_at: true,
                    category: true,
                    price: false,
                    color_primary: false,
                    colors_child: false,
                    stock_status: false,
                    attributes: false
                },
                categories: [],
                collections: [],
                producers: [],
                brands: [],
                rooms: [],
                stock_statuses: [],
                filters: {
                },
                wooStatuses: [
                    {
                        id: 1,
                        label: "Zsynchronizowany",
                    },
                    {
                        id: 2,
                        label: "Zmieniony",
                    },
                    {
                        id: 3,
                        label: "Niezsynchronizowany",
                    },
                    {
                        id: 4,
                        label: "Błąd synchronizacji",
                    },
                ],
                normalizer(node) {
                    return {
                        id: node.id,
                        label: "#" + node.id + " " + node.name,
                        children:
                            node.subcategories && node.subcategories.length
                                ? node.subcategories
                                : 0,
                    };
                },
                test:{
                }
            }
        },
        components: {
            Pagination,
            Modal,
            Icon,
            Treeselect
        },
        mounted() {
            this.checkFilters();
            this.checkColumns();
            this.getProducts();
            this.getShops();
            this.getCategories();
            // this.getCollections();
            this.loadOptions(this.test);
            this.getProducers();
            this.getBrands();
            this.getRooms();
            this.getStockStatuses()

            this.$root.$on('addProductModalSuccess', () => {
                this.getProducts();
            });
        },
        beforeDestroy() {
            this.$root.$off('addProductModalSuccess');
            this.$root.$off('exportProducts');
        },
        watch: {
            selectedProducts: {
                handler: function() {
                    this.addSelectedProductsToLocalStorage();
                },
                deep: true
            },
            columns: {
                handler: function() {
                    this.addColumnsToLocalStorage();
                },
                deep: true
            }
        },
        computed: {
            toggleSelectProducts: {
                get: function () {
                    return this.products.data ? this.selectedProducts.length == this.products.data.length : false;
                },
                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.products.data.forEach(function (product) {
                            selected.push(product.id);
                        });
                    }

                    this.selectedProducts = selected;
                }
            }
        },
        methods: {
            loadOptions({ searchQuery, callback }) {

                var params = {
                    'search': searchQuery,
                };

                try {
                    axios.get(API_BASE_URL + "/product-collections", {
                        params: params
                    }).then((response) => {
                        this.collections = response.data;
                        callback(null,this.collections);
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }

            },
            checkFilters(){
                if (localStorage.filters) {
                    this.filters = JSON.parse(localStorage.getItem("filters"));
                    this.filtersFilled();
                }
            },
            checkColumns(){
                if (localStorage.columns) {
                    this.columns = JSON.parse(localStorage.getItem("columns"));
                }
            },
            filtersFilled(){
                for (let key in this.filters) {
                    if((!Array.isArray(this.filters[key]) && this.filters[key]!= null) || (Array.isArray(this.filters[key]) && this.filters[key].length)){
                        this.showFilters = true;
                    }
                }
            },
            getStockStatuses(){
                try {
                    axios.get(API_BASE_URL + "/product-stock-statuses").then((response) => {
                        this.stock_statuses = response.data;
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            getRooms(){
                try {
                    var params = {
                        'shop_code': this.$store.state.shop.current
                    };
                    axios.get(API_BASE_URL + "/product-rooms", {
                        params: params
                    }).then((response) => {
                        this.rooms = response.data;
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            getBrands(){
                try {
                    axios.get(API_BASE_URL + "/product-brands").then((response) => {
                        this.brands = response.data;
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            getProducers(){
                try {
                    axios.get(API_BASE_URL + "/product-producers").then((response) => {
                        this.producers = response.data;
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            clearSelectedProducts(){
                this.selectedProducts=[];
            },
            addSelectedProductsToLocalStorage() {
                localStorage.setItem("selectedProducts", JSON.stringify(this.selectedProducts));
            },
            addFiltersToLocalStorage() {
                localStorage.setItem("filters", JSON.stringify(this.filters));
            },
            addColumnsToLocalStorage() {
                localStorage.setItem("columns", JSON.stringify(this.columns));
            },
            getCategories(){
                try {
                    axios.get(API_BASE_URL + "/product-categories").then((response) => {
                        this.categories = response.data;
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            // getCollections (){
            //     try{
            //         axios.get(API_BASE_URL + "/product-collections").then((response) => {
            //             this.collections = response.data;
            //         })
            //     } catch(error){
            //         this.$root.$emit("noticesBox", error.response.data);
            //     }
            // },
            async getProducts() {
                this.isLoading = true;
                this.selectedProducts = [];
                try {
                    var params = {
                        'page': this.products.current_page,
                        'orderBy': this.orderBy,
                        'order': this.order,
                        'filters': this.filters,
                    };

                    params.shop_code = this.$store.state.shop.current;

                    if (this.search != '') params.search = this.search;

                    const response = await axios.get(API_BASE_URL + '/products', {
                        params: params
                    });
                    this.products = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.isLoading = false;
            },
            async deleteProduct(product) {
                this.showModal = true;
                this.productToDelete = product;
            },
            async confirmDeleteProduct() {
                await axios.delete(API_BASE_URL + '/products/' + this.productToDelete.id);
                this.showModal = false;
                this.getProducts();
            },
            async getShops() {
                try {
                    const response = await axios.get(API_BASE_URL + '/shops');
                    this.shops = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            prepareShops(translations) {
                let shops = this.shops;
                let returnedShops = {};
                let parsedChilds = {};

                translations.forEach(function (trans) {
                    parsedChilds[trans.shop_code] = trans.product_id;
                });

                shops.forEach(function (shop) {
                    returnedShops[shop.code] = {
                        shop_code: shop.code,
                        default: shop.default,
                        product_id: parsedChilds[shop.code]
                    }
                });

                return returnedShops;
            },
            getPhotoUrl(path) {
                return API_BASE_URL + '/' + path;
            },
            sortObjects() {
                event.target.setAttribute('order', ((this.order == 'asc') ? 'desc' : 'asc'));
                this.orderBy = event.target.getAttribute('order-by');
                this.order = event.target.getAttribute('order');

                this.getProducts();
            },
            searchProducts: _.debounce(function () {
                this.products.current_page = 1;
                this.getProducts()
            }, 400),
            goToOrAdd(shop, type, productId) {

                let vm = this;
                if (type == 'addTranslation') {

                    vm.$root.$emit('addProductModal', {
                        translation: shop.shop_code,
                        addTranslationTo: productId
                    });

                } else if (type == 'productEdit') {
                    vm.$store.dispatch('shop/setShop', {
                        code: shop.shop_code,
                        preventReload: true
                    }).then(
                        function () {
                            vm.$router.push({
                                name: 'productEdit',
                                params: {
                                    id: shop.product_id
                                }
                            });
                        }
                    );
                }
            },
            wooSyncStatus
        }
    }
</script>