<template>
    <div>
        <!-- Product description -->
        <div class="col-24">
            <div
                class="product-form__header d-flex align-items-center"
            >
                <div
                    :class="{
                        'is-hidden': isKeyValue,
                    }"
                    @click.prevent="
                        isKeyValue = !isKeyValue
                    "
                >
                    <icon
                        icon="plus"
                        v-if="!isKeyValue"
                        icon-classes="icon__plus--large icon--black icon--rotated"
                    />
                    <icon
                        icon="plus"
                        v-else
                        icon-classes="icon__plus--large icon--black"
                    />
                </div>
                <span 
                    class="product-form__header-title">
                    KeyValue</span
                >
            </div>
        </div>

        <transition name="transition-slide">
            <div class="col-24 product-form__content" v-if="!isKeyValue">
                <div class="row pb-5">
                    <div class="col-24">
                        <table class="items-table">
                            <thead class="items-table__thead">
                                <tr class="items-table__tr">
                                    <th class="items-table__th">
                                        ID
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Tekst
                                    </th>
                                    <th class="items-table__th p-0 text-right">
                                        Akcje
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="items-table__tbody">
                                <template v-for="object in objects">
                                    <tr class="items-table__tr" v-bind:key="'object_' + object.id">
                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">Id</div>
                                            {{ object.id }}
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Tekst
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.key == object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.text"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.text }}
                                            </div>
                                        </td>

                                        <td class="items-table__td items-table__td--button">
                                            <div class="form-box__mobile-element">Akcje</div>
                                            <div class="d-flex justify-content-end">
                                                <button v-if="editingObject.key == object.id" class="button-icon"
                                                    @click.prevent="saveObject()">
                                                    <icon icon="save" />
                                                </button>
                                                <button v-else class="button-icon" @click.prevent="editObject(object)">
                                                    <icon icon="edit" />
                                                </button>

                                                <button class="button-icon button-icon--ml-10" @click="deleteObject(object)">
                                                    <icon icon="trash" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element"></div>
                                        #
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Tekst
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.text"
                                            />
                                        </div>
                                    </td>
                                    <td class="items-table__td items-table__td--button">
                                        <div class="form-box__mobile-element"></div>
                                        <div class="d-flex justify-content-end">
                                            <button class="button button--icon" @click="storeObject()">
                                                <icon icon="plus"
                                                    icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                                <span class="button__text">Dodaj nowy</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </transition>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie parametru
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć ten parametr <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.text }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>
<script>
    import Icon from "../parts/Icon";
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import axios from 'axios';
    import Modal from './../parts/Modal';

    export default {
        data() {
            return {
                isKeyValue: false,
                newObject: {},
                editingObject: {},
                showModal: false,
                objectToDelete: {},
                objects: {},
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                    }
                },
            }
        },
        components: {
            Icon,
            Modal
        },
        created() {
            this.getKeyValues();
        },
        props: {
            product: {
                type: Object,
                required: false
            },
        },
        methods: {
            getKeyValues(){
                var params = {
                    'shop_code': this.$store.state.shop.current
                };

                try {
                    axios.get(API_BASE_URL + "/product-key-values", {
                        params: params
                        }).then((response) => {
                        this.objects = response.data;
                    });
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            async storeObject(data = false) {
                if (!data) data = this.newObject;
                if (!data.shop_code) data.shop_code = this.$store.state.shop.current
                if (!data.product_id) data.product_id = this.product.id;
                try {
                    const response = await axios.post(API_BASE_URL + '/product-key-values', data);
                    this.newObject = {};
                    this.objects = response.data;
                    this.editingObject = {};
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.id;
            },
            saveObject() {
                this.storeObject(this.editingObject);
            },
            async confirmDeleteObject() {
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/product-key-values/' + this.objectToDelete.id);
                    this.getKeyValues();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            deleteObject(object) {
                this.showModal = true;
                this.objectToDelete = object;
            }
        }
    }
</script>