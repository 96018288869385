<template>
    <div class="product-form product-form--ecommerce row">
        <div v-if="isLoaded" class="w-100">
            <transition name="transition-slide">
                <div class="col-24 product-form__content  product-form__content--big" v-if="!isHidden">
                    <div class="row">
                        <div class="col-24 col-xl-18">
                                                        <div class="row">
                                <div class="col-24 col-xl">
                                    <div class="product-form__input-wrap">
                                        <div class="product-form__title">
                                            Widoczność katalogu
                                        </div>
                                        <treeselect class="'vue-treeselect"
                                            v-if="catalog_visibilities.length > 0"
                                            v-model="product.catalog_visibility"
                                            :multiple="false"
                                            :options="catalog_visibilities"
                                            placeholder=""
                                            :clearable="false"
                                        />
                                        <div
                                            v-else
                                            class="product-form__note"
                                            v-text="
                                                isLoading
                                                    ? 'Loading...'
                                                    : 'No purposes defined in database.'
                                            "
                                        ></div>
                                    </div>
                                </div>

                                <div class="col-24 col-xl">
                                    <div class="product-form__input-wrap">
                                        <div class="product-form__title">
                                            Status
                                        </div>
                                        <treeselect
                                            v-if="product_statuses.length > 0"
                                            v-model="product.product_status"
                                            :multiple="false"
                                            :options="product_statuses"
                                            placeholder=""
                                            :clearable="false"
                                            :default-expand-level="Infinity"
                                        />
                                        <div
                                            v-else
                                            class="product-form__note"
                                            v-text="
                                                isLoading
                                                    ? 'Loading...'
                                                    : product.product_status
                                                    ? 'No defined product statuses in database.'
                                                    : 'You need to first add unit.'
                                            "
                                        ></div>
                                    </div>
                                </div>
                                <div class="col-24 col-xl">
                                    <div class="product-form__input-wrap">
                                        <div class="product-form__title">
                                            Opublikowany
                                        </div>
                                        <label
                                            :for="
                                                'check-is_published-' +
                                                    product.id
                                            "
                                            class="product-form__checkbox"
                                        >
                                            <input
                                                :id="
                                                    'check-is_published-' +
                                                        product.id
                                                "
                                                class="product-form__checkbox--input"
                                                type="checkbox"
                                                v-model="
                                                    product.is_published
                                                "
                                            />
                                            <span
                                                class="product-form__checkbox-checkmark"
                                                v-text="
                                                    product.is_published
                                                        ? 'Yes'
                                                        : 'No'
                                                "
                                            ></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col-24 col-xl">
                                    <div class="product-form__input-wrap">
                                        <div class="product-form__title">
                                            Widoczny
                                        </div>
                                        <label
                                            :for="
                                                'check-is_visible-' +
                                                    product.id
                                            "
                                            class="product-form__checkbox"
                                        >
                                            <input
                                                :id="
                                                    'check-is_visible-' +
                                                        product.id
                                                "
                                                class="product-form__checkbox--input"
                                                type="checkbox"
                                                v-model="
                                                    product.is_visible
                                                "
                                            />
                                            <span
                                                class="product-form__checkbox-checkmark"
                                                v-text="
                                                    product.is_visible
                                                        ? 'Yes'
                                                        : 'No'
                                                "
                                            ></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-24 col-xl">
                                    <div class="product-form__input-wrap">
                                        <div class='product-form__title'>
                                            Cena
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="product.price"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-24 offset-xl-1 col-xl-5">
                            <h6
                                class="shop-switcher__title mt-3"
                            >
                                WooCommerce:
                            </h6>
                        
                            <button
                                class="button shop-switcher__add mt-3 w-100"
                                @click.prevent="showSyncModal = true"
                                v-if="!product.woo_updated"
                            >
                                <icon
                                    icon="reverse"
                                    icon-classes="button__icon button__icon--left"
                                ></icon>
                                Synchronizuj
                            </button>

                             <div class="mt-2">
                                Aktualny sklep: 
                            </div>
                             <div class="mt-2">
                                {{product.shop_code}}

                                <div
                                    :class="[
                                        'items-table__status',
                                        'items-table__status--' +
                                            wooSyncStatus(product),
                                    ]"
                                ></div>
                            </div>
                            <div class="mt-2" v-if="product_shop_variants.length">
                                Pozostałe sklepy:
                            </div>
                            <div class="mt-2">
                                <div v-for="(variant, index) in product_shop_variants" :key="index">
                                    {{variant.shop_code}}
                                    <div
                                        :class="[
                                            'items-table__status',
                                            'items-table__status--' +
                                                wooSyncStatus(variant),
                                        ]"
                                    ></div>
                                </div>
                            </div>

                            <button
                                class="button button--red button--full button--icon  mt-3 w-100"
                                @click.prevent="showDeleteModal = true"
                                :disabled="isDeleting"
                            >
                                <div
                                    class="loader button__icon button__icon--left"
                                    v-if="isDeleting"
                                ></div>
                                <icon
                                    v-else
                                    icon="save"
                                    icon-classes="button__icon button__icon--left icon--white"
                                />
                                <span
                                    class="button__text"
                                    v-text="
                                        isDeleting ? 'Saving...' : 'Usuwanie'
                                    "
                                    >Usuń produkt</span
                                >
                            </button>
                            <button
                                class="button button--icon mt-3 w-100"
                                @click.prevent="showModalAddShop =true"
                                :disabled="shopsToAdd.length == 0"
                            >
                                <icon
                                    icon="plus"
                                    icon-classes="button__icon button__icon--left icon--white"
                                />
                                <span
                                    class="button__text"
                                    >Dodaj produkt na inny sklep</span
                                >
                            </button>

                            <button
                                class="button button--icon mt-3 w-100"
                                @click.prevent="showModalDeleteShop =true"
                            >
                                <icon
                                    icon="trash"
                                    icon-classes="button__icon button__icon--left icon--white"
                                />
                                <span
                                    class="button__text"
                                    >Usuń produkt z innego sklepu</span
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-else>
            Loading...
        </div>

        <!-- Modal delete product -->
        <modal
            :show="showDeleteModal"
            size="md"
            @close="showDeleteModal = !showDeleteModal"
        >
            <div slot="header" class="notice-modal__header--title">
                Ususwanie produktu
            </div>

            <div slot="body">
                <div>
                    Czy usunąć produkt
                    <strong>{{ product.id + " - " + product.name }}</strong
                    >?
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="button button--small button--grey"
                    @click="showDeleteModal = false"
                >
                    Nie
                </button>
                <button
                    class="button button--small button--ml-10"
                    @click="confirmDeleteProduct()"
                >
                    Tak
                </button>
            </div>
        </modal>

        <!-- Modal sync product -->
        <modal
            :show="showSyncModal"
            size="md"
            @close="showSyncModal = !showSyncModal"
        >
            <div slot="header" class="notice-modal__header--title">
                Synchronizacja produktu #{{ product.sku }} z WooCommerce
            </div>
            <div slot="body">
                <div>
                    <span v-if="!isSyncing"
                        >Czy zsynchronizować produkt #{{
                            product.sku
                        }}
                        z WooCommerce?</span
                    >

                    <div
                        class="spim-loader-container"
                        :class="{ 'is-loading': isSyncing }"
                        v-if="isSyncing"
                    >
                        <div class="spim-loader">
                            <div class="spim-loader__rhombus-1"></div>
                            <div class="spim-loader__rhombus-2"></div>
                            <div class="spim-loader__rhombus-3"></div>
                            <div class="spim-loader__rhombus-4"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="button button--small button--grey"
                    @click="showSyncModal = false"
                >
                    Anuluj
                </button>
                <button
                    class="button button--small button--ml-10"
                    :disabled="isSyncing"
                    @click="syncProduct()"
                >
                    Synchronizuj
                </button>
            </div>
        </modal>

        <!-- Modal add shop -->
        <modal :show="showModalAddShop" size="md" @close="showModalAddShop = !showModalAddShop;">
            <div slot="header" class="notice-modal__header--title">
                Dodawanie sklepu
            </div>

            <div slot="body">
                <div class="col-24">
                    <div class="form-box">
                        <label class="form-box__label form-box__label--mt">
                            Sklep:
                        </label>
                        <treeselect
                            :multiple="false"
                            :options="shopsToAdd"
                            v-model="shopToAdd"
                            :clearable="false"
                            :searchable="false"
                            :normalizer="shopNormalizer"
                            placeholder=""
                        />
                    </div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModalAddShop = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="addShop()">
                    Tak
                </button>
            </div>
        </modal>

        <!-- Modal delete shop -->
        <modal :show="showModalDeleteShop" size="md" @close="showModalDeleteShop = !showModalDeleteShop;">
            <div slot="header" class="notice-modal__header--title">
                Usuawnie sklepu
            </div>

            <div slot="body">
                <div class="col-24">
                    <div class="form-box">
                        <label class="form-box__label form-box__label--mt">
                            Sklep:
                        </label>
                        <treeselect
                            :multiple="false"
                            :options="shopsToDelete"
                            v-model="shopToDelete"
                            :clearable="false"
                            :searchable="false"
                            :normalizer="shopNormalizer"
                            placeholder=""
                        />
                    </div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModalDeleteShop = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="deleteShop()">
                    Tak
                </button>
            </div>
        </modal>
    </div>
</template>
<script>
    import axios from "axios";
    import { wooSyncStatus } from "../../helpers/status";
    import Icon from "../parts/Icon";
    import Modal from "./../parts/Modal";
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Treeselect from "@riophae/vue-treeselect";

    export default {
        data() {
            return {
                showModalAddShop: false,
                showModalDeleteShop: false,
                shopToDelete: null,
                shopToAdd: null,
                isHidden: false,
                isLoaded: true,
                isDeleting: false,
                showDeleteModal: false,
                isSyncing: false,
                showSyncModal: false,
                product_shop_variants: [],
                catalog_visibilities: [
                    {
                        id: 'visible',
                        label: 'Sklep i wyniki wyszukiwania',
                    },
                    {
                        id: 'search',
                        label: 'Tylko wyniki wyszukiwania',
                    },
                    {
                        id: 'hidden',
                        label: 'Ukryty',
                    },
                    {
                        id: 'catalog',
                        label: 'Tylko sklep',
                    }
                ],
                product_statuses: [
                    {
                        id: 'in_preparation',
                        label: 'W przygotowaniu',
                    },
                    {
                        id: 'active',
                        label: 'Aktywny',
                    },
                    {
                        id: 'withdrawed',
                        label: 'Wycofany',
                    },
                    {
                        id: 'canceled',
                        label: 'Anulowany',
                    }
                ],
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                        children: node.subcategories
                    }
                },
                shopNormalizer(node) {
                    return {
                        id: node.code,
                        label: node.name,
                    };
                },
            }
        },
        props: {
            product: {
                type: Object,
                required: false
            }
        },
        components: {
            Icon,
            Modal,
            Treeselect
        },
        mounted() {
            this.$root.$on('saveProduct', () => {
                this.storeProduct();
            });
        },
        beforeDestroy() {
            this.$root.$off('saveProduct');
        },
        created(){
            this.getProductShopVariants();
        },
        computed: {
            shops() {
                return this.$store.state.shop.all;
            },
            shopsToAdd() {
                if(this.product.shops){
                   var shops = this.shops.filter(o1 => !this.product.shops.some(o2 => o1.id === o2.id)) 
                }
                return shops;
            },
            shopsToDelete() {
                if(this.product.shops){
                   var shops = this.product.shops.filter(function(o) {
                    return o.code !== 'pl';
                });
                }
                return shops;
            },
        },
        methods: {
            async getProductShopVariants(){
                var params = {
                    'product_id': this.$route.params.id,
                    'shop_code': this.$route.params.shop,
                };
                try{
                    axios.get(API_BASE_URL + "/product-shop-variants", {
                        params: params
                    }).then((response) => {
                        this.product_shop_variants = response.data;
                    });
                    console.log(this.product_shop_variants);
                } catch (error) {
                    this.$root.$emit("noticesBox", error.response.data);
                }
            },
            async addShop(){
                let data = {
                    'product_id': this.product.id,
                    'shop_code': this.shopToAdd
                };
                try {
                    await axios.post(API_BASE_URL + '/product-shop-variant', data);
                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: 'Dodano wariant dla wybranego sklepu'
                    });
                    this.getProduct();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.showModalAddShop = false;
                this.shopsToAdd();
                this.shopsToDelete();
            },
            async deleteShop(){
                let params = {
                    'product_id': this.product.id,
                    'shop_code': this.shopToDelete
                };
                try {
                    await axios.delete(API_BASE_URL + '/product-shop-variant', {
                        params: params
                    });

                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: 'Usunięto wariant dla wybranego sklepu'
                    });
                    if(this.shopToDelete == this.$route.params.shop){
                        this.$root.$emit('noticesBox', {
                            type: 'success',
                            message: 'Edytujesz produkt w sklepie Polskim'
                        });
                        this.switchLocale('pl');
                    }
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.showModalDeleteShop = false;
                this.shopsToAdd();
                this.shopsToDelete();
            },
            wooSyncStatus,
            confirmDeleteProduct() {
                let vm = this;
                this.showDeleteModal = false;

                axios
                    .delete(API_BASE_URL + "/products/" + vm.product.id)
                    .then(() => {
                        this.$root.$emit("noticesBox", {
                            message: "Product successfully removed.",
                            type: "success",
                        });

                        vm.isDeleted = setTimeout(() => {
                            vm.$router.push({
                                name: "productsList",
                            });
                        }, 2000);
                    })
                    .catch((error) => {
                        this.$root.$emit("noticesBox", error.response.data);
                    });
            },
            syncProduct() {
                this.isSyncing = true;
                axios
                    .post(API_BASE_URL + "/products/sync", {
                        products: [this.product.id],
                        shop_code: this.$store.state.shop.current,
                    })
                    .then((response) => {
                        this.isSyncing = false;

                        const product = response.data.products[0];

                        if (product && product.id == this.product.id) {
                            this.showSyncModal = false;

                            this.product.woo_added = product.woo_added;
                            this.product.woo_updated = product.woo_updated;
                            this.product.woo_error = product.woo_error;

                            if (this.product.woo_error) {
                                this.$root.$emit("noticesBox", {
                                    message:
                                        "There was error while syncing with WooCommerce. Please try again later, or contact administator.",
                                });
                            } else {
                                this.$root.$emit("noticesBox", {
                                    type: "success",
                                    message: "Product successfuly synchronized.",
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.isSyncing = false;
                        this.$root.$emit("noticesBox", error.response.data);
                    });
            },
        },
    }
</script>