<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-collections"
        title = "Lista kolekcji"
        :numeral_text = "['kolekcja', 'kolekcje', 'kolekcji']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>