<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista atrybutów</h1>
                </div>
            </div>
        </div>
        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div class="content row">
                <categories-list v-if="categories.data" :categories="categories.data"
                    @addCategory="addCategory" @editCategory="editCategory" @deleteCategory="deleteCategory" :withoutImage="true">
                </categories-list>
            </div>
        </div>

        <!-- Pagination -->
        <pagination :pagination="categories" @paginate="getCategories()" :offset="1"></pagination>
        <!-- Pagination -->

        <!-- Modal edit -->
        <modal :show="showEditModal" size="lg" @close="showEditModal = !showEditModal;">
            <div slot="header" class="notice-modal__header--title">
                Edycja kolekcji {{ '#' + editingCategory.id }}
            </div>

            <div slot="body">
                <div class="form-box">
                    <label class="form-box__label">
                        Nazwa atrybutu:
                    </label>

                    <input v-model="editingCategory.name" class="form-box__input" @keypress.enter.prevent="saveCategory()" />

                    <label class="form-box__label form-box__label--mt">
                        Typ pola:
                    </label>
                    <treeselect class="vue-treeselect__control--small" v-if="types.length > 0" v-model="editingCategory.type" :multiple="false" :options="types" placeholder="" :clearable="false"  @change="changeShowOptions(editingCategory.type)"/>
                    <table class="items-table" v-show="showOptions">
                        <thead class="items-table__thead">
                            <tr class="items-table__tr">
                                <th class="items-table__th">
                                    ID
                                </th>
                                <th class="items-table__th items-table__th">
                                    Nazwa
                                </th>
                                <th class="items-table__th p-0 text-right">
                                    Akcje
                                </th>
                            </tr>
                        </thead>
                        <tbody class="items-table__tbody">
                            <template v-for="(option, index) in options">
                                <tr class="items-table__tr" v-bind:key="'object_' + option.id">
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Id</div>
                                        {{ option.id }}
                                    </td>

                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Nazwa
                                        </div>
                                        <div class="d-flex align-items-center" v-if="editingObject.id === option.id">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="option.name"
                                            />
                                        </div>
                                        <div v-else>
                                            {{ option.name }}
                                        </div>
                                    </td>

                                    <td class="items-table__td items-table__td--button">
                                        <div class="form-box__mobile-element">Akcje</div>
                                        <div class="d-flex justify-content-end">
                                            <button v-if="editingObject.id === option.id" class="button-icon"
                                                @click.prevent="saveObject()">
                                                <icon icon="save" />
                                            </button>
                                            <button v-else class="button-icon" @click.prevent="editObject(option)">
                                                <icon icon="edit" />
                                            </button>

                                            <button class="button-icon button-icon--ml-10" @click="deleteObject(index, option)">
                                                <icon icon="trash" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <tr>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element"></div>
                                    #
                                </td>
                                <td class="items-table__td" style="width: 100%">
                                    <div class="form-box__mobile-element">
                                        Nazwa
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <textarea
                                            class="product-form__input"
                                            type="text"
                                            v-model="newObject.name"
                                            style="min-height: 80px;"
                                        >
                                        </textarea>
                                    </div>
                                </td>
                                

                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element"></div>
                                    <div class="d-flex justify-content-end">
                                        <button class="button button--icon" @click="storeObject()">
                                            <icon icon="plus"
                                                icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                            <span class="button__text">Dodaj</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--grey" @click="showEditModal = false">
                    Anuluj
                </button>
                <button class="button button--icon button--ml-10" @click="saveCategory()">
                    <div class="loader button__icon button__icon--left" v-if="editingCategory.isSaving"></div>
                    <icon v-else icon="save"
                        icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                    <span class="button__text">Zapisz kolekcję</span>
                </button>
            </div>
        </modal>

        <!-- Modal add -->
        <modal :show="showAddModal" size="lg" @close="showAddModal = !showAddModal;">
            <div slot="header" class="notice-modal__header--title">
                <template v-if="newCategory.parent_id">
                    Dodawanie podkategorii do kategorii {{ '#' + newCategory.parent_id }}
                </template>
            </div>

            <div slot="body">
                <div class="form-box">
                    <label class="form-box__label">
                        Nazwa atrybutu:
                    </label>

                    <input v-model="newCategory.name" class="form-box__input" @keypress.enter.prevent="saveCategory()" />

                    <label class="form-box__label form-box__label--mt">
                        Typ pola:
                    </label>
                    <treeselect class="vue-treeselect__control--small" v-if="types.length > 0" v-model="newCategory.type" :multiple="false" :options="types" placeholder="" :clearable="false"/>
                    <table class="items-table">
                        <thead class="items-table__thead">
                            <tr class="items-table__tr">
                                <th class="items-table__th">
                                    ID
                                </th>
                                <th class="items-table__th items-table__th">
                                    Nazwa
                                </th>
                                <th class="items-table__th p-0 text-right">
                                    Akcje
                                </th>
                            </tr>
                        </thead>
                        <tbody class="items-table__tbody">
                            <template v-for="(option, index) in options">
                                <tr class="items-table__tr" v-bind:key="'object_' + option.id">
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Id</div>
                                        {{ option.id }}
                                    </td>

                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Nazwa
                                        </div>
                                        <div class="d-flex align-items-center" v-if="editingObject.id === option.id">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="option.name"
                                            />
                                        </div>
                                        <div v-else>
                                            {{ option.name }}
                                        </div>
                                    </td>

                                    <td class="items-table__td items-table__td--button">
                                        <div class="form-box__mobile-element">Akcje</div>
                                        <div class="d-flex justify-content-end">
                                            <button v-if="editingObject.id === option.id" class="button-icon"
                                                @click.prevent="saveObject()">
                                                <icon icon="save" />
                                            </button>
                                            <button v-else class="button-icon" @click.prevent="editObject(option)">
                                                <icon icon="edit" />
                                            </button>

                                            <button class="button-icon button-icon--ml-10" @click="deleteObject(index, option)">
                                                <icon icon="trash" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <tr>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element"></div>
                                    #
                                </td>
                                <td class="items-table__td" style="width: 100%">
                                    <div class="form-box__mobile-element">
                                        Nazwa
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <textarea
                                            class="product-form__input"
                                            type="text"
                                            v-model="newObject.name"
                                            style="min-height: 80px;"
                                        >
                                        </textarea>
                                    </div>
                                </td>
                                

                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element"></div>
                                    <div class="d-flex justify-content-end">
                                        <button class="button button--icon" @click="storeObject()">
                                            <icon icon="plus"
                                                icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                            <span class="button__text">Dodaj</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--grey" @click="showAddModal = false">
                    Anuluj
                </button>
                <button class="button button--icon button--ml-10" @click="storeCategory()">
                    <icon icon="plus" icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                    <span class="button__text">Dodaj</span>
                </button>
            </div>
        </modal>

        <!-- Modal delete -->
        <modal :show="showDeleteModal" size="md" @close="showDeleteModal = !showDeleteModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie kolekcji
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć kolekcję
                    <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteCategory()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';
    import CategoriesList from './../parts/CategoriesList';
    import Pagination from './../parts/Pagination.vue';
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                showOptions: false,
                isLoading: true,
                current_page: 1,
                newCategory: {},
                categories: {},
                editingCategory: {},
                newObject: {},
                editingObject: {},
                showDeleteModal: false,
                showAddModal: false,
                showEditModal: false,
                objectToDelete: {},
                options: {},
                options_to_delete: [],
                test2: null,
                types: [
                    {
                        id: 'checkbox',
                        label: 'checkbox'
                    },{
                        id: 'tekst',
                        label: 'tekst'
                    },{
                        id: 'słownik',
                        label: 'słownik'
                    }
                ],
            }
        },
        async created() {
            this.getCategories();
        },
        components: {
            Icon,
            Modal,
            CategoriesList,
            Pagination,
            Treeselect
        },
        mounted() {
            this.$root.$on('addCategory', (categoryId) => {
                this.addCategory(categoryId);
            });
            this.$root.$on('editCategory', (category) => {
                this.editCategory(category);
            });
            this.$root.$on('deleteCategory', (category) => {
                this.deleteCategory(category);
            });

        },
        beforeDestroy() {
            this.$root.$off('addCategory');
            this.$root.$off('editCategory');
            this.$root.$off('deleteCategory');
        },
        methods: {
            async getCategories() {
                this.isLoading = true;
                try {
                    var params = {
                        'page': this.categories.current_page,
                        'perPage': 70,
                    };
                    const response = await axios.get(API_BASE_URL + '/product-attributes', {
                        params: params
                    });
                    this.categories = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.isLoading = false;
            },
            async storeCategory(data = false) {
                if (!data) data = this.newCategory;
                if(data.type != 'słownik'){
                    this.options = []
                }
                console.log(this.options);
                data.options = this.options;
                data.shop_code = this.$store.state.shop.current;
                data.page = this.categories.current_page;
                data.perPage = 70;

                try {
                    const response = await axios.post(API_BASE_URL + '/product-attributes', data);
                    this.newCategory.name = '';
                    this.categories = response.data;
                    this.editingCategory.isSaving = false;
                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: 'Poprawnie zapisano kategorię.'
                    });
                } catch (error) {
                    this.editingCategory.isSaving = false;
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            addCategory(categoryId) {
                this.showAddModal = true;
                this.newCategory.parent_id = categoryId;
            },
            editCategory(object) {
                let category = object.category;
                this.editingCategory.id = category.id;
                this.editingCategory.name = category.name;
                this.editingCategory.type = category.type;
                this.options = category.options;
                if(this.editingCategory.type=="słownik"){
                    this.showOptions = true;
                }else{
                    this.showOptions = false;
                }
                
                this.showEditModal = true;
            },
            deleteCategory(category) {
                this.showDeleteModal = true;
                this.objectToDelete = category;
            },
            async confirmDeleteCategory() {
                this.showDeleteModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/product-attributes/' + this.objectToDelete.id);
                    this.getCategories();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            saveCategory() {
                this.editingCategory.isSaving = true;
                this.storeCategory(this.editingCategory);
            },



            async storeObject() {
                this.newObject.id = null;
                console.log(this.newObject);
                this.options.push(this.newObject);
                this.newObject = {};
                this.editingObject = {};
            },
            editObject(object) {
                this.editingObject = object;
            },
            saveObject() {
                this.editingObject = {};
            },
            deleteObject(idToDelete, ToDeleteObject) {
                if(ToDeleteObject.id){
                    this.options_to_delete.push(ToDeleteObject.id);
                }

                this.options.splice(idToDelete, 1);
            },

        },
        changeShowOptions(type){
            console.log(type);
        }
    }
</script>