<template>
    <div class="product-form product-form--ecommerce row">
        <div v-if="isLoaded" class="w-100">
            <transition name="transition-slide">
                <div class="col-24" v-if="!isHidden">
                    <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Tytuł SEO (główny)
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.title_seo"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Opis do Marketplace
                                </div>
                                <div class="d-flex align-items-center">
                                    <editor
                                        v-if="product.id"
                                        class="quill-editor-ecom w-100"
                                        :inputContent="product.description_marketplace"
                                        :options="quillConfigMinimal"
                                        @update="product.description_marketplace = $event"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    SEO Description (krótki opis)
                                </div>
                                <div class="d-flex align-items-center">
                                    <textarea
                                        class="product-form__input product-form__input--textarea"
                                        v-model="product.description_seo"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                     <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Feed description
                                </div>
                                <div class="d-flex align-items-center">
                                    <textarea
                                        class="product-form__input product-form__input--textarea"
                                        v-model="product.description_feed"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    EMPIK Description
                                </div>
                                <div class="d-flex align-items-center">
                                    <textarea
                                        class="product-form__input product-form__input--textarea"
                                        v-model="product.description_empik"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <KeyValue
                    :product="product">
                    </KeyValue>
                </div>
            </transition>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>
<script>
    import Editor from "./../Editor.vue";
    import KeyValue from "./KeyValue.vue";
    export default {
        data() {
            return {
                isHidden: false,
                isLoaded: true,
                isLoading: true,
                quillConfigMinimal: {
                    modules: {
                        toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "ordered" }, { list: "bullet" }],
                        ],
                    },
                },
                preview: false,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                        children: node.subcategories
                    }
                },
            }
        },
        props: {
            product: {
                type: Object,
                required: false
            }
        },
        components: {
            Editor,
            KeyValue
        },
        mounted() {
            this.$root.$on('saveProduct', () => {
                this.storeProduct();
            });
        },
        beforeDestroy() {
            this.$root.$off('saveProduct');
        },
    }
</script>