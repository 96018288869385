<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-code-kinds"
        title = "Lista rodzajów kodu"
        :numeral_text = "['rodzaj kodu', 'rodzaje kodu', 'rodzajów kodu']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>