<template>
    <div class="product-form product-form--basic-info row">
        <div v-if="isLoaded" class="w-100">
            <transition name="transition-slide">
                <div class="col-24" v-if="!isHidden">
                    <div class="row">
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Produkt SKU
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.sku"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Nazwa kolekcji
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.collections.length > 0"
                                    v-model="product.collection_id"
                                    :multiple="false"
                                    :options="dictionaries.collections"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :default-expand-level="Infinity"
                                    @input="v => product.color_primary_id = v || null"
                                    disabled
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.collections
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Nazwa produktu - krótka
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="text"
                                        v-model="product.name_short"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Sizes
                        v-if="product.sizes"
                        :product="product">
                    </Sizes>
                    <div class="row">

                        <div class="col-24 col-lg-8">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Kolor podstawowy
                                </div>
                                <div class="d-flex align-items-center">
                                   <treeselect
                                        class="vue-treeselect"
                                        v-if="dictionaries.colors.length > 0"
                                        v-model="product.color_primary_id"
                                        :multiple="false"
                                        :options="dictionaries.colors"
                                        placeholder=""
                                        :normalizer="normalizer"
                                        :value-consists-of="'LEAF_PRIORITY'"
                                        :default-expand-level="Infinity"
                                        @input="v => product.color_primary_id = v || null"
                                    />

                                    <div
                                        v-else
                                        class="product-form__note"
                                        v-text="
                                            isLoading
                                                ? 'Loading...'
                                                : dictionaries.colors
                                                ? 'No defined net units in database.'
                                                : 'You need to add net unit first.'
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Kolory potomne
                                </div>
                                <div class="d-flex align-items-center">
                                   <treeselect
                                    sort-value-by="INDEX"
                                    :default-expand-level="Infinity"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    v-model="product.colors_child"
                                    :multiple="true"
                                    :options="dictionaries.colors"
                                    :normalizer="normalizer"
                                />
                                    <!-- <div
                                        v-else
                                        class="product-form__note"
                                        v-text="
                                            isLoading
                                                ? 'Loading...'
                                                : colors
                                                ? 'No defined net units in database.'
                                                : 'You need to add net unit first.'
                                        "
                                    ></div> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-lg-8">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Materiał podstawowy
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.materials.length > 0"
                                    v-model="product.material_primary_id"
                                    :multiple="false"
                                    :options="dictionaries.materials"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :default-expand-level="Infinity"
                                    @input="v => product.material_primary_id = v || null"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.materials
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>


                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Materiał potomne
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.materials.length > 0"
                                    sort-value-by="INDEX"
                                    :default-expand-level="Infinity"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    v-model="product.materials_child"
                                    :multiple="true"
                                    :options="dictionaries.materials"
                                    :normalizer="normalizer"
                                    :clearable="true"
                                    placeholder=""
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.materials
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Kategoria domyślna Woo
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.categories.length > 0"
                                    sort-value-by="INDEX"
                                    :default-expand-level="Infinity"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    v-model="product.categories"
                                    :multiple="true"
                                    :options="dictionaries.categories"
                                    :normalizer="normalizer"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.categories
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Pokoje
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.rooms.length > 0"
                                    sort-value-by="INDEX"
                                    :default-expand-level="Infinity"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    v-model="product.rooms"
                                    :multiple="true"
                                    :options="dictionaries.rooms"
                                    :normalizer="normalizer"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.rooms
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Styl
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.styles.length > 0"
                                    v-model="product.style_id"
                                    :multiple="false"
                                    :options="dictionaries.styles"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    :default-expand-level="Infinity"
                                    @input="v => product.style_id = v || null"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.styles
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>


                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Brand
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.brands.length > 0"
                                    v-model="product.brand_id"
                                    :multiple="false"
                                    :options="dictionaries.brands"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    @input="v => product.brand_id = v || null"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.brands
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Czas minimalny produktu w magazynie
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="number"
                                        v-model="product.min_time_in_stock"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Czas maksymalny produktu w magazynie
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="number"
                                        v-model="product.max_time_in_stock"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Czas minimalny braku produktu w magazynie
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="number"
                                        v-model="product.min_time_no_in_stock"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Czas maksymalny braku produktu w magazynie
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="number"
                                        v-model="product.max_time_no_in_stock"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Status produktu (WOO?)
                                </div>
                                <treeselect
                                    class="vue-treeselect"
                                    v-if="dictionaries.stock_statuses.length > 0"
                                    v-model="product.stock_status_id"
                                    :multiple="false"
                                    :options="dictionaries.stock_statuses"
                                    placeholder=""
                                    :normalizer="normalizer"
                                    @input="v => product.stock_status_id = v || null"
                                />
                                <div
                                    v-else
                                    class="product-form__note"
                                    v-text="
                                        isLoading
                                            ? 'Loading...'
                                            : dictionaries.stock_statuses
                                            ? 'No defined net units in database.'
                                            : 'You need to add net unit first.'
                                    "
                                ></div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Wyłączenie możliwości sprzedaży
                                </div>
                                <label
                                    :for="
                                        'check-is_excluded_sale-' +
                                            product.id
                                    "
                                    class="product-form__checkbox"
                                >
                                    <input
                                        :id="
                                            'check-is_excluded_sale-' +
                                                product.id
                                        "
                                        class="product-form__checkbox--input"
                                        type="checkbox"
                                        v-model="product.is_excluded_sale"
                                    />
                                    <span
                                        class="product-form__checkbox-checkmark"
                                        v-text="
                                            product.is_excluded_sale
                                                ? 'Yes'
                                                : 'No'
                                        "
                                    ></span>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Koszt dostawy (WOO?)
                                </div>
                                <div class="d-flex align-items-center">
                                    <input
                                        class="product-form__input"
                                        type="number"
                                        v-model="product.kt1"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-24 col-xl">
                            <div class="product-form__input-wrap">
                                <div class='product-form__title'>
                                    Shipment size (WOO?)
                                </div>
                                <div class="d-flex align-items-center">
                                    <treeselect
                                        class="vue-treeselect__control--small"
                                        v-if="dictionaries.shipment_sizes.length > 0"
                                        v-model="product.shipment_size_id"
                                        :multiple="false"
                                        :options="dictionaries.shipment_sizes"
                                        placeholder=""
                                        :clearable="false"
                                        :normalizer="normalizer"
                                        @input="v => product.shipment_size_id = v || null"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </transition>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>
<script>
    import Treeselect from "@riophae/vue-treeselect";
    import Sizes from "./Sizes.vue"
    export default {
        data() {
            return {
                isHidden: false,
                isLoaded: true,
                isLoading: true,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: "#" + node.id + " " + node.name,
                        // children:
                        //     node.subcategories && node.subcategories.length
                        //         ? node.subcategories
                        //         : 0,
                    };
                },
            }
        },
        props: {
            product: {
                type: Object,
                required: false
            },
            dictionaries: {},
        },
        components: {
            Treeselect,
            Sizes
        },
        mounted() {
            this.$root.$on('saveProduct', () => {
                this.storeProduct();
            });
        },
        beforeDestroy() {
            this.$root.$off('saveProduct');
        },
        methods: {
        }
    }
</script>