<template>
        <div class="site-wrap" :class="{'site-wrap--with-padding': loggedIn}">
            <template v-if="loggedIn">
                <navbar></navbar>
                <submenu></submenu>
                <div class="site-content">
                    <div class="container-fluid">
                        <router-view :key="$route.path"></router-view>
                    </div>
                    <modal-add-product/>
                </div>
                <div class="site-footer">
                    <span class="site-footer__content">
                        powered by
                        <a href="https://mda.pl/" title="MDA" target="_blank">
                            <img class="site-footer__img" src="./img/mda.png" />
                        </a>
                    </span>
                </div>
            </template>
            <template v-else>
                <router-view :key="$route.path"></router-view>
            </template>

            <notices-box/>
        </div>
</template>

<script>
    import Navbar from '@/components/parts/Navbar';
    import Submenu from '@/components/parts/Submenu';
    import NoticesBox from '@/components/parts/NoticesBox';
    import ModalAddProduct from '@/components/parts/ModalAddProduct';

    export default {
        name: 'App',
        components: {
            Navbar,
            Submenu,
            NoticesBox,
            ModalAddProduct
        },
        computed: {
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        }
    };
</script>