<template>
    <div :class="(!cat) ? 'tree-list' : 'tree-list__item'">
        <div v-if="!cat" class="tree-list__brand">
            <div class="tree-list__actions">
            </div>
        </div>
        <div v-else class="tree-list__cat">
            <div class="d-flex align-items-center">
                <img v-if="!withoutImage" :src="getApiPath(cat.image_path)" style="width: 30px; margin-right: 10px;"/>
                <div>{{ '#' + cat.id + ' ' + cat.name }}</div>
            </div>
            <div class="tree-list__actions">
                <button class="button-icon" @click="addCategory(cat.id)">
                    <icon icon="plus"/>
                </button>

                <button class="button-icon button-icon--ml-10" @click="editCategory(cat)">
                    <icon icon="edit"/>
                </button>

                <button class="button-icon button-icon--ml-10" @click="deleteCategory(cat)">
                    <icon icon="trash"/>
                </button>
            </div>
        </div>

        <categories-list v-for="category in categories" :withoutImage="withoutImage" :categories="category.subcategories" :cat="category" 
            :key="'category_' + category.id">
        </categories-list>
    </div>
</template>


<script>
    const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;
    import Icon from './Icon';
    export default {
        props: ['withoutImage', 'cat', 'categories'],
        name: 'categories-list',
        components: {
            Icon
        },
        methods: {
            async addCategory(categoryId) {
                this.$root.$emit('addCategory', categoryId);
            },
            async editCategory(category) {
                let object = {
                    category : category,
                };
                this.$root.$emit('editCategory', object);
            },
            async deleteCategory(category) {
                this.$root.$emit('deleteCategory', category);
            },
            getApiPath(path) {
                if (path) {
                    let timestamp = new Date().getTime();

                    return API_PHOTO_URL + path + '?' + timestamp;
                } else return require('../../img/default.png');
            },
        }
    }
</script>