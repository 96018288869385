import Vue from 'vue'
import App from './App.vue';
import { router } from './router';
import store from './store/index';
import interceptorsSetup from './helpers/axios-interceptor';
import VueI18n from 'vue-i18n';
import VueQuillEditor from 'vue-quill-editor'
import Datepicker from 'vuejs-datepicker';
import moment from 'moment'
// Styles
import './scss/main.scss';

// Filters
import './filters/pretty-bytes';
import './filters/pretty-date';
import './filters/numerals-text';
import './filters/uppercase';
import './filters/lowercase';
import { i18n } from './i18n'

Vue.prototype.moment = moment

Vue.use(Datepicker);


// Vue config
Vue.config.productionTip = false;

// Shops
Vue.use(VueI18n);

// Quill
Vue.use(VueQuillEditor, /* { default global options } */);

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme

// Axios interceptors setup
interceptorsSetup();

// go VUE!
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');