<template>
    <div>
        <!-- Product description -->
        <div class="col-24">
            <div
                class="product-form__header d-flex align-items-center"
            >
                <div
                    :class="{
                        'is-hidden': isSizes,
                    }"
                    @click.prevent="
                        isSizes = !isSizes
                    "
                >
                    <icon
                        icon="plus"
                        v-if="!isSizes"
                        icon-classes="icon__plus--large icon--black icon--rotated"
                    />
                    <icon
                        icon="plus"
                        v-else
                        icon-classes="icon__plus--large icon--black"
                    />
                </div>
                <span 
                    class="product-form__header-title">
                    Wymiary</span
                >
            </div>
        </div>
        <transition name="transition-slide">
            <div class="col-24 product-form__content" v-if="!isSizes">
                <div class="row pb-5">
                    <div class="col-24">
                        <table class="items-table">
                            <thead class="items-table__thead">
                                <tr class="items-table__tr">
                                    <th class="items-table__th">
                                        ID
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Szerokość
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Wysokość
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Głębokość
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Pojemność
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Średnica
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Opis
                                    </th>
                                    <th class="items-table__th p-0 text-right">
                                        Akcje
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="items-table__tbody">
                                <template v-for="(object, index) in product.sizes">
                                    <tr class="items-table__tr" v-bind:key="'object_' + object.id">
                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">Id</div>
                                            {{ object.id }}
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Szerokość
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.id === object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.width"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.width }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Wysokość
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.id === object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.height"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.height }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Głębokość
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.id === object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.depth"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.depth }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Pojemność
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.id === object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.capacity"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.capacity }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Średnica
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.id === object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.diameter"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.diameter }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">
                                                Opis
                                            </div>
                                            <div class="d-flex align-items-center" v-if="editingObject.id === object.id">
                                                <input
                                                    class="product-form__input"
                                                    type="text"
                                                    v-model="object.description"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ object.description }}
                                            </div>
                                        </td>

                                        <td class="items-table__td items-table__td--button">
                                            <div class="form-box__mobile-element">Akcje</div>
                                            <div class="d-flex justify-content-end">
                                                <button v-if="editingObject.id === object.id" class="button-icon"
                                                    @click.prevent="saveObject()">
                                                    <icon icon="save" />
                                                </button>
                                                <button v-else class="button-icon" @click.prevent="editObject(object)">
                                                    <icon icon="edit" />
                                                </button>

                                                <button class="button-icon button-icon--ml-10" @click="deleteObject(index, object)">
                                                    <icon icon="trash" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element"></div>
                                        #
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Szerokość
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.width"
                                            />
                                        </div>
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Wysokość
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.height"
                                            />
                                        </div>
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Głębokość
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.depth"
                                            />
                                        </div>
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Pojemność
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.capacity"
                                            />
                                        </div>
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Średnica
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.diameter"
                                            />
                                        </div>
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Opis
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <input
                                                class="product-form__input"
                                                type="text"
                                                v-model="newObject.description"
                                            />
                                        </div>
                                    </td>

                                    <td class="items-table__td items-table__td--button">
                                        <div class="form-box__mobile-element"></div>
                                        <div class="d-flex justify-content-end">
                                            <button class="button button--icon" @click="storeObject()">
                                                <icon icon="plus"
                                                    icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                                <span class="button__text">Dodaj</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import Icon from "../parts/Icon";

    export default {
        data() {
            return {
                isSizes: false,
                newObject: {},
                editingObject: {},
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                    }
                },
            }
        },
        components: {
            Icon,
        },
        props: {
            product: {
                type: Object,
                required: false
            },
        },
        mounted() {
            this.product.sizes_to_delete = [];
        },
        methods: {
            async storeObject() {
                this.newObject.id = null;
                this.product.sizes.push(this.newObject);
                this.newObject = {};
                this.editingObject = {};
            },
            editObject(object) {
                this.editingObject = object;
            },
            saveObject() {
                this.editingObject = {};
            },
            async deleteObject(idToDelete, ToDeleteObject) {
                this.product.sizes_to_delete.push(ToDeleteObject.id);
                this.product.sizes.splice(idToDelete, 1);
            },
        }
    }
</script>