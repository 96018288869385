import Vue from 'vue';
import Vuex from 'vuex';

import {
    auth
} from './modules/auth.module';

import {
    shop
} from './modules/shop.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        shop
    }
});