<template>

    <div class="products-list">

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Aktywność użytkowników</h1>
                </div>
            </div>
        </div>
        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div v-if="logs.data.length > 0" class="content">
                <table class="items-table">
                    <thead class="items-table__thead">
                        <tr class="items-table__tr">
                            <th class="items-table__th">
                                #
                            </th>
                            <th class="items-table__th">
                                Data
                            </th>
                            <th class="items-table__th">
                                Użytkownik
                            </th>
                            <th class="items-table__th">
                                Akcja
                            </th>
                            <th class="items-table__th">
                                Obiekt
                            </th>
                            <th class="items-table__th pr-0 text-right">
                                Akcje
                            </th>
                        </tr>
                    </thead>

                    <tbody class="items-table__tbody">

                        <template v-for="log in logs.data">
                            <tr class="items-table__tr" v-bind:key="log.id">

                                <td class="items-table__td">
                                    {{ log.id }}
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Data</div>
                                    {{ log.updated_at | prettyDate(true) }}
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Użytkownik</div>
                                    <span v-text="(log.user) ? log.user.email : ((getEmail(log)) ? getEmail(log) : '-')"></span>
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Akcja</div>
                                    {{ log.description }}
                                </td>

                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Obiekt</div>
                                    <span v-text="(log.subject_id != null) ? (log.subject_type + '#' + log.subject_id) : ((log.subject_type) ? log.subject_type : '-')"></span>
                                </td>

                                <td class="items-table__td pr-0">
                                    <div class="form-box__mobile-element">Akcje</div>
                                    <div class="d-flex justify-content-end">
                                        <button class="button-icon" v-if="hasComparison(log)" @click="showComparison(log)">
                                            <icon icon="preview"></icon>
                                        </button>
                                        <button class="button-icon" v-else @click="showPreview(log)">
                                            <icon icon="preview"></icon>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>

            </div><!-- // Products list-->
            <div v-else class="content">
                Brak wyników.
            </div>
        </div>

        <!-- Pagination -->
        <pagination :pagination="logs" @paginate="getLogs()" :offset="1"></pagination>
        <!-- Pagination -->

        <!-- Modal delete -->
        <modal :show="showModal" size="lg" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                Podgląd logu #{{ previewLog.id }}
            </div>

            <div slot="body">
                <div class="row" v-if="previewLog.preview">
                    <vue-json-pretty :showLength="true" :data="previewLog.preview" :highlightMouseoverNode="true"></vue-json-pretty>
                </div>
                <div v-else class="row">
                    <div class="col-24 col-xl-12" v-if="previewLog.old">
                        <span v-text="(previewLog.new) ? 'Przed:' : ''"></span>
                        <vue-json-pretty :showLength="true" :data="previewLog.old" :highlightMouseoverNode="true"></vue-json-pretty>
                    </div>
                    <div class="col-24 mt-5 mt-xl-0 col-xl-12" v-if="previewLog.new">
                        <span v-text="(previewLog.old) ? 'Po:' : ''"></span>
                        <vue-json-pretty :showLength="true" :data="previewLog.new" :highlightMouseoverNode="true"></vue-json-pretty>
                    </div>
                </div>
            </div>
        </modal>

    </div><!-- // Products -->

</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Pagination from './parts/Pagination';
    import Icon from './parts/Icon';
    import Modal from './parts/Modal';
    import VueJsonPretty from 'vue-json-pretty'

    export default {
        data() {
            return {
                logs: {
                    isLoading: true,
                    total: 0,
                    per_page: 2,
                    from: 1,
                    to: 0,
                    current_page: 1,
                    data: {}
                },
                showModal: false,
                previewLog: {}
            }
        },
        components: {
            Pagination,
            Icon,
            Modal,
            VueJsonPretty
        },
        mounted() {
            this.getLogs();
        },
        methods: {
            async getLogs() {
                this.isLoading = true;
                try {
                    var params = {
                        'page': this.logs.current_page
                    };

                    const response = await axios.get(API_BASE_URL + '/logs', {
                        params: params
                    });

                    this.logs = response.data;

                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.isLoading = false;
            },
            showComparison(data) {
                this.previewLog.preview = false;
                let array = JSON.parse(data.properties);

                this.previewLog.id = data.id;

                if (typeof array.old != 'undefined') {
                    this.previewLog.old = array.old;
                } else {
                    this.previewLog.old = false;
                }

                if (typeof array.attributes != 'undefined') {
                    this.previewLog.new = array.attributes;
                } else {
                    this.previewLog.new = false;
                }

                this.showModal = true;
            },
            hasComparison(data){
                let array = JSON.parse(data.properties);

                return (typeof array.old != 'undefined' || typeof array.attributes != 'undefined');
            },
            getEmail(data){
                let array = JSON.parse(data.properties);

                return (array.email) ? array.email : false;
            },
            showPreview(data) {
                this.previewLog.old = false;
                this.previewLog.new = false;

                let array = JSON.parse(data.properties);

                this.previewLog.id = data.id;
                this.previewLog.preview = array;

                this.showModal = true;
            }
        }
    }
</script>