import Vue from 'vue';
import VueLocalStorage from 'vue-localstorage';
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
Vue.use(VueLocalStorage);

export const shop = {
    namespaced: true,
    state: {
        // shop: Vue.localStorage.get('shop'),
        current: Vue.localStorage.get("current"),
        all: JSON.parse(Vue.localStorage.get("all")),
    },
    mutations: {
        SET_SHOP(state, shop) {
            Vue.localStorage.set("current", shop.code);
            state.current = shop.code;

            if (shop.preventReload == false) {
                location.reload();
            }
        },
        FETCH_SHOPS(state, shops) {
            Vue.localStorage.set("all", JSON.stringify(shops));
            state.all = shops;
        },
    },
    actions: {
        setShop({ commit }, shop) {
            axios
                .get(API_BASE_URL + "/shops", {
                    headers: {
                        Locale: shop.code,
                    },
                })
                .then((response) => {
                    commit("FETCH_SHOPS", response.data);
                    commit("SET_SHOP", shop);
                });
        },
        fetchShops({ commit }) {
            try {
                axios.get(API_BASE_URL + "/shops").then((response) => {
                    commit("FETCH_SHOPS", response.data);
                });
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
            }
        },
    },
};