<template>
    <div v-if="product" class="mb-3">
        <div class='product-form__title'>Główne zdjęcie</div>
        <input
            type="file"
            ref="photosInput"
            @change="onImageChange"
            :disabled="isStoring"
            style="display: none;"
        />

        <div
            class="product-photos"
            v-if="product.main_photo && product.main_photo.length"
        >
            <div
                v-for="photo in product.main_photo"
                :key="'photo_' + photo.id"
                class="product-photo"
            >
                <div class="product-photo__container">
                    <img
                        :src="getApiPath(photo.path)"
                        class="product-photo__img"

                    />

                    <button
                        class="button-icon button-icon--ml-10 button-icon--bg-white product-photo__unlink"
                        :disabled="isStoring"
                        @click="deleteObject(photo)"
                    >
                        <icon icon="unlink" />
                    </button>
                </div>
                <div
                    class="product-photo__actions d-flex align-items-center justify-content-end"
                >
                    <input
                        v-model="photo.alt"
                        class="product-form__input product-photo__alt-input"
                        @change="setPhotoAlt(photo)"
                    />
                </div>
            </div>
        </div>
        <div class="col-24" v-else>Nie dodano jeszcze głównego zdjęcia.</div>

        <div class="d-flex mt-3" v-if="product.main_photo.length < 1">
            <button
                class="button button--icon"
                @click="$refs.photosInput.click()"
                :disabled="isStoring"
            >
                <icon
                    v-if="!isStoring"
                    icon="plus"
                    icon-classes="icon--white button__icon button__icon--left icon--white"
                ></icon>
                <div
                    v-else
                    class="loader button__icon button__icon--left"
                ></div>
                <span
                    class="button__text"
                    v-text="isStoring ? 'Wysyłanie...' : 'Dodaj zdjęcie'"
                >
                    Dodaj zdjęcie</span
                >
            </button>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie zdjęcia
            </div>

            <div slot="body">
                Czy napewno chcesz usunąć to zdjęcie?
                <div class="product-photo mt-4">
                    <img :src="getApiPath(objectToDelete.path)" />
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="button button--small button--grey"
                    @click="showModal = false"
                >
                    Nie
                </button>
                <button
                    class="button button--small button--ml-10"
                    @click="confirmdeleteObject()"
                >
                    Tak
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Icon from "../parts/Icon";
import Modal from "../parts/Modal";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_PHOTO_URL = process.env.VUE_APP_API_PHOTO_URL;

export default {
    data() {
        return {
            image: "",
            imagePath: "",
            isStoring: false,
            showModal: false,
            defaultSelected: false,
            objectToDelete: false,
        };
    },
    components: {
        Icon,
        Modal,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    methods: {
        savePhoto() {
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };
            let vm = this;
            let formData = new FormData();
            vm.isStoring = true;
            formData.append("image", this.image);
            formData.append("product_id", this.product.id);
            formData.append("type", "main");
            formData.append("locale", this.$store.state.shop.current);

            axios
                .post(API_BASE_URL + "/products/photos", formData, config)
                .then(function(response) {
                    vm.product.main_photo = _.cloneDeep(
                        response.data.main_photo
                    );
                    vm.isStoring = false;
                    vm.$root.$emit("noticesBox", {
                        type: "success",
                        message: "Image saved successfully.",
                    });
                })
                .catch(function(error) {
                    vm.isStoring = false;
                    vm.$root.$emit("noticesBox", error.response.data);
                });

            this.$refs.photosInput.value = "";
        },
        onImageChange(e) {
            this.image = e.target.files[0];
            this.savePhoto();
        },
        getApiPath(path) {
            if (path) {
                let timestamp = new Date().getTime();
                return API_PHOTO_URL + path + "?" + timestamp;
            } else return require("../../img/default.png");
        },
        setPhotoAlt(photo) {
            let vm = this;

            axios
                .put(API_BASE_URL + "/products/photos/alt", {
                    id: photo.id,
                    alt: photo.alt,
                    locale: this.$store.state.shop.current
                })
                .then(() => {
                    vm.$root.$emit("noticesBox", {
                        type: "success",
                        message: "Alt changed.",
                    });
                })
                .catch(function(error) {
                    vm.$emit("storingProduct", false);
                    vm.$root.$emit("noticesBox", error.response.data);
                });
        },
        async deleteObject(object) {
            this.showModal = true;
            this.objectToDelete = object;
        },
        async confirmdeleteObject() {
            let vm = this;
            this.showModal = false;
            await axios.delete(API_BASE_URL + "/products/photos/delete/" + this.objectToDelete.relation_id).then(function (response) {
                vm.product.main_photo = _.cloneDeep(response.data.main_photo);
                vm.$root.$emit("noticesBox", {
                    type: "success",
                    message: "Image removed.",
                });
            })
            .catch(function (error) {
                this.$root.$emit("noticesBox", error.response.data);
            });
        },
    },
};
</script>
