<template>
    <div>
        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista pokojów Woo</h1>
                </div>
            </div>
        </div>
        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div class="content row">
                <categories-list v-if="categories" :categories="categories"
                    @addCategory="addCategory" @editCategory="editCategory" @deleteCategory="deleteCategory" :withoutImage="true">
                </categories-list>
            </div>
        </div>

        <!-- Modal edit -->
        <modal :show="showEditModal" size="md" @close="showEditModal = !showEditModal;">
            <div slot="header" class="notice-modal__header--title">
                Edycja pokoju {{ '#' + editingCategory.id }}
            </div>

            <div slot="body">
                <div class="col-24">
                    <div class="form-box">
                        <label class="form-box__label">
                            Nazwa:
                        </label>
                        <input v-model="editingCategory.name" class="form-box__input" @keypress.enter.prevent="saveCategory()"/>

                        <label class="form-box__label form-box__label--mt" v-if="parentsSelect">
                            Rodzic:
                        </label>
                        <treeselect v-if="parentsSelect" v-model="editingCategory.parent_id"
                            :multiple="false" :options="parentsSelect" :reduce="category => category.id"
                            :normalizer="normalizerParentsSelect" :clearable="true" :default-expand-level="10" placeholder=""/>
                    </div>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--grey" @click="showEditModal = false">
                    Anuluj
                </button>
                <button class="button button--icon button--ml-10" @click="saveCategory()">
                    <div class="loader button__icon button__icon--left" v-if="editingCategory.isSaving"></div>
                    <icon v-else icon="save"
                        icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                    <span class="button__text">Zapisz pokój</span>
                </button>
            </div>
        </modal>

        <!-- Modal add -->
        <modal :show="showAddModal" size="md" @close="showAddModal = !showAddModal;">
            <div slot="header" class="notice-modal__header--title">
                <template v-if="newCategory.parent_id">
                    Dodawanie podkategorii do pokoju {{ '#' + newCategory.parent_id }}
                </template>
                <template v-else>
                    Dodawanie pokoju
                </template>
            </div>

            <div slot="body">
                <div class="form-box">
                    <label class="form-box__label">
                        Podaj nazwę nowego pokoju:
                    </label>
                    <input v-model="newCategory.name" class="form-box__input" @keypress.enter.prevent="storeCategory()"/>

                    <label class="form-box__label form-box__label--mt" v-if="parentsSelect">
                        Rodzic:
                    </label>
                    <treeselect v-if="parentsSelect" v-model="newCategory.parent_id"
                        :multiple="false" :options="parentsSelect" :reduce="category => category.id"
                        :normalizer="normalizerParentsSelect" :clearable="true" :default-expand-level="10" placeholder=""/>
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--grey" @click="showAddModal = false">
                    Anuluj
                </button>
                <button class="button button--icon button--ml-10" @click="storeCategory()">
                    <icon icon="plus" icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                    <span class="button__text">Dodaj</span>
                </button>
            </div>
        </modal>

        <!-- Modal delete -->
        <modal :show="showDeleteModal" size="md" @close="showDeleteModal = !showDeleteModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie pokoju
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć pokój
                    <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showDeleteModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteCategory()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';
    import CategoriesList from './../parts/CategoriesList';
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                isLoading: true,
                newCategory: {},
                categories: [],
                editingCategory: {},
                showDeleteModal: false,
                showAddModal: false,
                showEditModal: false,
                objectToDelete: {},
                parentsSelect: [],
                isStoring: false,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name
                    }
                },
                normalizerParentsSelect(node) {
                    return {
                        id: node.id,
                        label: '#' + node.id + ' ' + node.name,
                        children: node.subcategories
                    }
                }
            }
        },
        async created() {
            this.getCategories();
        },
        components: {
            Icon,
            Modal,
            CategoriesList,
            Treeselect
        },
        mounted() {
            this.$root.$on('addCategory', (categoryId) => {
                this.addCategory(categoryId);
            });
            this.$root.$on('editCategory', (category) => {
                this.editCategory(category);
            });
            this.$root.$on('deleteCategory', (category) => {
                this.deleteCategory(category);
            });
        },
        beforeDestroy() {
            this.$root.$off('addCategory');
            this.$root.$off('editCategory');
            this.$root.$off('deleteCategory');
        },
        methods: {
            async getCategories() {
                this.isLoading = true;
                var params = {
                    'shop_code' :this.$store.state.shop.current,
                };

                try {
                    const response = await axios.get(API_BASE_URL + '/product-rooms', {
                        params: params
                    });
                    this.categories = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.isLoading = false;
            },
            async storeCategory(data = false) {
                if (!data) data = this.newCategory;
                if (!data.shop_code) data.shop_code = this.$store.state.shop.current;

                try {
                    const response = await axios.post(API_BASE_URL + '/product-rooms', data);
                    this.newCategory.name = '';
                    this.categories = response.data;
                    this.editingCategory.isSaving = false;
                    this.$root.$emit('noticesBox', {
                        type: 'success',
                        message: 'Poprawnie zapisano kategorię.'
                    });
                } catch (error) {
                    this.editingCategory.isSaving = false;
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            addCategory(categoryId) {
                this.showAddModal = true;
                this.parentsSelect = this.categories;
                this.newCategory.parent_id = categoryId;
            },
            editCategory(object) {
                let category = object.category;
                this.parentsSelect = this.categories;
                this.editingCategory.id = category.id;
                this.editingCategory.name = category.name;
                this.editingCategory.parent_id = category.parent_id;
                this.showEditModal = true;
            },
            deleteCategory(category) {
                this.showDeleteModal = true;
                this.objectToDelete = category;
            },
            async confirmDeleteCategory() {
                this.showDeleteModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/product-rooms/' + this.objectToDelete.id);
                    this.getCategories();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            saveCategory() {
                this.editingCategory.isSaving = true;
                this.storeCategory(this.editingCategory);
            },
        }
    }
</script>