<template>
    <div class="product-form product-form--ecommerce row">
        <div v-if="isLoaded" class="w-100">
            <div>
                <!-- Product description -->
                <div class="col-24">
                    <div
                        class="product-form__header d-flex align-items-center"
                    >
                        <div
                            :class="{
                                'is-hidden': isProductGeneralInformations,
                            }"
                            @click.prevent="
                                isProductGeneralInformations = !isProductGeneralInformations
                            "
                        >
                            <icon
                                icon="plus"
                                v-if="!isProductGeneralInformations"
                                icon-classes="icon__plus--large icon--black icon--rotated"
                            />
                            <icon
                                icon="plus"
                                v-else
                                icon-classes="icon__plus--large icon--black"
                            />
                        </div>
                        <span 
                            class="product-form__header-title">
                            Ogólne informacje</span
                        >
                    </div>
                </div>

                <transition name="transition-slide">
                    <div
                        class="col-24 product-form__content"
                        v-if="!isProductGeneralInformations"
                    >
                        <div class="row">
                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Produkt SKU
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.sku"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Nazwa
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.erp_name"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Język tłumaczonych treści
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center">
                                        <treeselect
                                            :id="'shop_select'"
                                            :multiple="false"
                                            :options="shops"
                                            :value="currentShop"
                                            :clearable="false"
                                            :searchable="false"
                                            :normalizer="shopNormalizer"
                                            placeholder=""
                                            :disabled="true"
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Stawka sprzedaży VAT
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            :multiple="false"
                                            :options="dictionaries.vats"
                                            :normalizer="normalizer"
                                            :default-expand-level="1"
                                            placeholder=""
                                            v-model="product.purchase_vat_id"
                                            @input="v => product.purchase_vat_id = v || null"
                                            />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Stawka VAT zakupu
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            :multiple="false"
                                            :options="dictionaries.vats"
                                            :normalizer="normalizer"
                                            :default-expand-level="1"
                                            placeholder=""
                                            v-model="product.sales_vat_id"
                                            @input="v => product.sales_vat_id = v || null"
                                            />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Typ
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            :multiple="false"
                                            :options="dictionaries.types"
                                            :normalizer="normalizer"
                                            :default-expand-level="1"
                                            placeholder=""
                                            v-model="product.type_id"
                                            @input="v => product.type_id = v || null"
                                            />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Kategoria produktu
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            :multiple="false"
                                            :options="dictionaries.erp_categories"
                                            :normalizer="normalizer"
                                            :default-expand-level="1"
                                            placeholder=""
                                            v-model="product.erp_category_id"
                                            @input="v => product.erp_category_id = v || null"
                                            />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Producent
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            :multiple="false"
                                            :options="dictionaries.producers"
                                            :normalizer="normalizer"
                                            :default-expand-level="1"
                                            placeholder=""
                                            v-model="product.producer_id"
                                            @input="v => product.producer_id = v || null"
                                            />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Marka
                                    </div>
                                    <treeselect
                                        class="vue-treeselect"
                                        v-if="dictionaries.brands.length > 0"
                                        v-model="product.brand_id"
                                        :multiple="false"
                                        :options="dictionaries.brands"
                                        placeholder=""
                                        :normalizer="normalizer"
                                        @input="v => product.brand_id = v || null"
                                    />
                                    <div
                                        v-else
                                        class="product-form__note"
                                        v-text="
                                            isLoading
                                                ? 'Loading...'
                                                : dictionaries.brands
                                                ? 'No defined net units in database.'
                                                : 'You need to add net unit first.'
                                        "
                                    ></div>
                                </div>
                            </div>

                             <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Numer katalogowy
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.catalog_number"
                                            :disabled="true"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Kraj pochodzenia
                                    </div>
                                    <treeselect
                                        class="vue-treeselect"
                                        v-if="dictionaries.countries.length > 0"
                                        v-model="product.country_orgin_id"
                                        :multiple="false"
                                        :options="dictionaries.countries"
                                        placeholder=""
                                        :normalizer="normalizer"
                                        @input="v => product.country_orgin_id = v || null"
                                    />
                                    <div
                                        v-else
                                        class="product-form__note"
                                        v-text="
                                            isLoading
                                                ? 'Loading...'
                                                : countries
                                                ? 'No defined net units in database.'
                                                : 'You need to add net unit first.'
                                        "
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>

            <div>
                <!-- Product description -->
                <div class="col-24">
                    <div
                        class="product-form__header d-flex align-items-center"
                    >
                        <div
                            :class="{
                                'is-hidden': isProductErpAttributes,
                            }"
                            @click.prevent="
                                isProductErpAttributes = !isProductErpAttributes
                            "
                        >
                            <icon
                                icon="plus"
                                v-if="!isProductErpAttributes"
                                icon-classes="icon__plus--large icon--black icon--rotated"
                            />
                            <icon
                                icon="plus"
                                v-else
                                icon-classes="icon__plus--large icon--black"
                            />
                        </div>
                        <span
                            class="product-form__header-title">
                            Atrybuty</span
                        >
                    </div>
                </div>

                <transition name="transition-slide">
                    <div
                        class="col-24 product-form__content"
                        v-if="!isProductErpAttributes"
                    >
                        <div class="row">
                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Kolekcja
                                    </div>
                                    <treeselect
                                        class="vue-treeselect"
                                        v-if="dictionaries.collections.length > 0"
                                        v-model="product.collection_id"
                                        :multiple="false"
                                        :options="dictionaries.collections"
                                        placeholder=""
                                        :normalizer="normalizer"
                                        :value-consists-of="'LEAF_PRIORITY'"
                                        :default-expand-level="Infinity"
                                        @input="v => product.collection_id = v || null"
                                    />
                                    <div
                                        v-else
                                        class="product-form__note"
                                        v-text="
                                            isLoading
                                                ? 'Loading...'
                                                : dictionaries.collections
                                                ? 'No defined net units in database.'
                                                : 'You need to add net unit first.'
                                        "
                                    ></div>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Nazwa producera
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.producer_name"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Dekor Konsimo (słownik?)
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.decor_konsimo"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Dropshipping
                                    </div>
                                    <label
                                        :for="
                                            'check-is_dropshipping-' +
                                                product.id
                                        "
                                        class="product-form__checkbox"
                                    >
                                        <input
                                            :id="
                                                'check-is_dropshipping-' +
                                                    product.id
                                            "
                                            class="product-form__checkbox--input"
                                            type="checkbox"
                                            v-model="product.is_dropshipping"
                                        />
                                        <span
                                            class="product-form__checkbox-checkmark"
                                            v-text="
                                                product.is_dropshipping
                                                    ? 'Yes'
                                                    : 'No'
                                            "
                                        ></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Aktywny ?
                                    </div>
                                    <label
                                        :for="
                                            'check-is_active-' +
                                                product.id
                                        "
                                        class="product-form__checkbox"
                                    >
                                        <input
                                            :id="
                                                'check-is_active-' +
                                                    product.id
                                            "
                                            class="product-form__checkbox--input"
                                            type="checkbox"
                                            v-model="product.is_active"
                                        />
                                        <span
                                            class="product-form__checkbox-checkmark"
                                            v-text="
                                                product.is_active
                                                    ? 'Yes'
                                                    : 'No'
                                            "
                                        ></span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        EECtransfer
                                    </div>
                                    <label
                                        :for="
                                            'check-is_eec_transfer-' +
                                                product.id
                                        "
                                        class="product-form__checkbox"
                                    >
                                        <input
                                            :id="
                                                'check-is_eec_transfer-' +
                                                    product.id
                                            "
                                            class="product-form__checkbox--input"
                                            type="checkbox"
                                            v-model="product.is_eec_transfer"
                                        />
                                        <span
                                            class="product-form__checkbox-checkmark"
                                            v-text="
                                                product.is_eec_transfer
                                                    ? 'Yes'
                                                    : 'No'
                                            "
                                        ></span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                       ID koloru producera
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.producer_color_code"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Identyfikator koloru
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            :value="product.color_code"
                                            :disabled="true"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Identyfikator modelu
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            :value="product.model_code"
                                            :disabled="true"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Ilość paczek
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="number"
                                            v-model="product.quantity_package"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Ilość w op. sprzedażowym 
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="number"
                                            v-model="product.quantity_in_package"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        KT1
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="number"
                                            v-model="product.kt1"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Przedsprzedaż
                                    </div>
                                    <label
                                        :for="
                                            'check-is_pre_sale-' +
                                                product.id
                                        "
                                        class="product-form__checkbox"
                                    >
                                        <input
                                            :id="
                                                'check-is_pre_sale-' +
                                                    product.id
                                            "
                                            class="product-form__checkbox--input"
                                            type="checkbox"
                                            v-model="product.is_pre_sale"
                                        />
                                        <span
                                            class="product-form__checkbox-checkmark"
                                            v-text="
                                                product.is_pre_sale
                                                    ? 'Yes'
                                                    : 'No'
                                            "
                                        ></span>
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Rok sezonu
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input
                                            class="product-form__input"
                                            type="text"
                                            v-model="product.season_year"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Wielkość
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            class="vue-treeselect__control--small"
                                            v-if="dictionaries.shipment_sizes.length > 0"
                                            v-model="product.shipment_size_id"
                                            :multiple="false"
                                            :options="dictionaries.shipment_sizes"
                                            placeholder=""
                                            :clearable="false"
                                            :normalizer="normalizer"
                                            @input="v => product.shipment_size_id = v || null"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Dostawca
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            :multiple="false"
                                            :options="dictionaries.producers"
                                            :normalizer="normalizer"
                                            :default-expand-level="1"
                                            placeholder=""
                                            v-model="product.suppiler_id"
                                            @input="v => product.suppiler_id = v || null"
                                            />
                                    </div>
                                </div>
                            </div>

                            <div class="col-24 col-xl">
                                <div class="product-form__input-wrap">
                                    <div class='product-form__title'>
                                        Sezon
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <treeselect
                                            class="vue-treeselect__control--small"
                                            v-if="dictionaries.seasons.length > 0"
                                            v-model="product.season_id"
                                            :multiple="false"
                                            :options="dictionaries.seasons"
                                            placeholder=""
                                            :clearable="false"
                                            :normalizer="normalizer"
                                            @input="v => product.season_id = v || null"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <Parameters
                :product="product"
                :dictionaries = "dictionaries"
                >
            </Parameters>

            <ConnectProducts
                :product="product">
            </ConnectProducts>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>
<script>
    import Icon from "../parts/Icon";
    import Treeselect from '@riophae/vue-treeselect';
    import ConnectProducts from "./ConnectProducts.vue";
    import Parameters from "./Parameters.vue";


    export default {
        data() {
            return {
                isHidden: false,
                isLoaded: true,
                isLoading: true,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                    }
                },
                shopNormalizer(node) {
                    return {
                        id: node.code,
                        label: node.name,
                    };
                },
                isProductGeneralInformations: false,
                isProductErpAttributes: false,
                title: 'Produkty powiązane',
            }
        },
        props: {
            product: {
                type: Object,
                required: false
            },
            dictionaries: {},
            shops: []
        },
        components: {
            Icon,
            Treeselect,
            ConnectProducts,
            Parameters
        },
        computed: {
            currentShop() {
                return this.$store.state.shop.current;
            },
        },
        mounted() {
            this.$root.$on('saveProduct', () => {
                this.storeProduct();
            });
            this.getColorForSku();
            this.getModelForSku();
            this.getCatalogNumberForSku();
        },
        beforeDestroy() {
            this.$root.$off('saveProduct');
        },
        watch: {
            'product.sku'() {
                this.getColorForSku();
                this.getModelForSku();
                this.getCatalogNumberForSku();
            }
        },
        methods: {
            getColorForSku(){
                //TODO
                if(this.product.color_code != this.product.sku.slice(9,12)){
                    this.product.color_code = this.product.sku.slice(9,12);
                }
            },
            getModelForSku(){
                //TODO
                if(this.product.model_code != this.product.sku.slice(6,8)){
                    this.product.model_code = this.product.sku.slice(6,8);
                }
            },
            getCatalogNumberForSku(){
                //TODO
                if(this.product.catalog_number != this.product.sku.replaceAll( '.', '')){
                    this.product.catalog_number = this.product.sku.replaceAll( '.', '');
                }
            },
        }
    }
</script>