<template>
    <div>
        <!-- Product description -->
        <div class="col-24">
            <div
                class="product-form__header d-flex align-items-center"
            >
                <div
                    :class="{
                        'is-hidden': isProductParameters,
                    }"
                    @click.prevent="
                        isProductParameters = !isProductParameters
                    "
                >
                    <icon
                        icon="plus"
                        v-if="!isProductParameters"
                        icon-classes="icon__plus--large icon--black icon--rotated"
                    />
                    <icon
                        icon="plus"
                        v-else
                        icon-classes="icon__plus--large icon--black"
                    />
                </div>
                <span 
                    class="product-form__header-title">
                    Parametry</span
                >
            </div>
        </div>

        <transition name="transition-slide">
            <div class="col-24 product-form__content" v-if="!isProductParameters">
                <div class="row pb-5">
                    <div class="col-24">
                        <table class="items-table">
                            <thead class="items-table__thead">
                                <tr class="items-table__tr">
                                    <th class="items-table__th">
                                        ID
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Rodzaj kodu
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Typ kodu
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Kod kreskowy
                                    </th>
                                    <th class="items-table__th items-table__th">
                                        Domyślny
                                    </th>
                                    <th class="items-table__th p-0 text-right">
                                        Akcje
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="items-table__tbody">
                                <template v-for="parameter in product.parameters">
                                    <tr class="items-table__tr" v-bind:key="'parameter_' + parameter.id">
                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">Id</div>
                                            {{ parameter.id }}
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">Rodzaj kodu</div>
                                            <div v-if="editingObject.key == parameter.id">
                                                <treeselect
                                                    class="vue-treeselect__control--small"
                                                    v-if="code_kinds.length > 0"
                                                    v-model="parameter.code_kind_id"
                                                    :multiple="false"
                                                    :options="code_kinds"
                                                    placeholder=""
                                                    :clearable="false"
                                                    :normalizer="normalizer"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ parameter.code_kind && parameter.code_kind.name }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">Typ kodu</div>
                                            <div v-if="editingObject.key == parameter.id">
                                                <treeselect
                                                    class="vue-treeselect__control--small"
                                                    v-if="code_types.length > 0"
                                                    v-model="parameter.code_type_id"
                                                    :multiple="false"
                                                    :options="code_types"
                                                    placeholder=""
                                                    :clearable="false"
                                                    :normalizer="normalizer"
                                                />
                                            </div>
                                            <div v-else>
                                                {{ parameter.code_type && parameter.code_type.name }}
                                            </div>
                                        </td>

                                        <td class="items-table__td">
                                            <div class="form-box__mobile-element">Kod kreskowy</div>
                                            <div v-if="editingObject.key == parameter.id">
                                                <input type="text" v-model="parameter.barcode" @keypress.enter.prevent="saveObject()"/>
                                            </div>
                                            <div v-else>
                                                {{ parameter.barcode }}
                                            </div>
                                        </td> 

                                         <td class="items-table__td">
                                            <div class="form-box__mobile-element">Domyślny</div>
                                            <div v-if="editingObject.key == parameter.id">
                                                <div class='product-form__title'>
                                                    Domyślny
                                                </div>
                                                <label
                                                    :for="
                                                        'check-is_defaulte-' +
                                                            editingObject.id
                                                    "
                                                    class="product-form__checkbox"
                                                >
                                                    <input
                                                        :id="
                                                            'check-is_default-' +
                                                                editingObject.id
                                                        "
                                                        class="product-form__checkbox--input"
                                                        type="checkbox"
                                                        v-model="editingObject.is_default"
                                                    />
                                                    <span
                                                        class="product-form__checkbox-checkmark"
                                                        v-text="
                                                            editingObject.is_default
                                                                ? 'Yes'
                                                                : 'No'
                                                        "
                                                    ></span>
                                                </label>
                                            </div>
                                            <div v-else>
                                                {{ parameter.is_default }}
                                            </div>
                                        </td>
                                        <td class="items-table__td items-table__td--button">
                                            <div class="form-box__mobile-element">Akcje</div>
                                            <div class="d-flex justify-content-end">
                                                <button v-if="editingObject.key == parameter.id" class="button-icon"
                                                    @click.prevent="saveObject()">
                                                    <icon icon="save" />
                                                </button>
                                                <button v-else class="button-icon" @click.prevent="editObject(parameter)">
                                                    <icon icon="edit" />
                                                </button>

                                                <button class="button-icon button-icon--ml-10" @click="deleteObject(parameter)">
                                                    <icon icon="trash" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element"></div>
                                        #
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Rodzaj kodu</div>
                                        <treeselect
                                            class="vue-treeselect__control--small"
                                            v-if="dictionaries.code_kinds.length > 0"
                                            v-model="newObject.code_kind_id"
                                            :multiple="false"
                                            :options="dictionaries.code_kinds"
                                            placeholder=""
                                            :clearable="false"
                                            :normalizer="normalizer"
                                        />
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Typ kodu</div>
                                        <treeselect
                                            class="vue-treeselect__control--small"
                                            v-if="dictionaries.code_types.length > 0"
                                            v-model="newObject.code_type_id"
                                            :multiple="false"
                                            :options="dictionaries.code_types"
                                            placeholder=""
                                            :clearable="false"
                                            :normalizer="normalizer"
                                        />
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Kod kreskowy</div>
                                        <input v-model="newObject.barcode" class="items-table__input" @keypress.enter.prevent="storeObject()"/>
                                    </td>
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Domyślny</div>
                                        <label
                                            :for="
                                                'check-is_default-' +
                                                    newObject.id
                                            "
                                            class="product-form__checkbox"
                                        >
                                            <input
                                                :id="
                                                    'check-is_default-' +
                                                        newObject.id
                                                "
                                                class="product-form__checkbox--input"
                                                type="checkbox"
                                                v-model="newObject.is_default"
                                            />
                                            <span
                                                class="product-form__checkbox-checkmark"
                                                v-text="
                                                    newObject.is_default
                                                        ? 'Yes'
                                                        : 'No'
                                                "
                                            ></span>
                                        </label>
                                    </td>
                                    <td class="items-table__td items-table__td--button">
                                        <div class="form-box__mobile-element"></div>
                                        <div class="d-flex justify-content-end">
                                            <button class="button button--icon" @click="storeObject()">
                                                <icon icon="plus"
                                                    icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                                <span class="button__text">Dodaj nowy</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </transition>
        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie parametru
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć ten parametr <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.barcode }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>
<script>
    import Icon from "../parts/Icon";
    import Treeselect from '@riophae/vue-treeselect';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import axios from 'axios';
    import Modal from './../parts/Modal';

    export default {
        data() {
            return {
                isProductParameters: false,
                newObject: {},
                editingObject: {},
                showModal: false,
                objectToDelete: {},
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                    }
                },
            }
        },
        components: {
            Icon,
            Treeselect,
            Modal
        },
        props: {
            product: {
                type: Object,
                required: false
            },
            dictionaries: {}
        },
        methods: {
            async storeObject(data = false) {
                if (!data) data = this.newObject;
                if (!data.shop_code) data.shop_code = this.$i18n.locale;
                if (!data.product_id) data.product_id = this.product.id;
                try {
                    const response = await axios.post(API_BASE_URL + '/product-parameters', data);
                    this.newObject = {};
                    this.objects = response.data;
                    this.editingObject = {};
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.id;
            },
            saveObject() {
                this.storeObject(this.editingObject);
            },
            async confirmDeleteObject() {
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + '/product-parameters/' + this.objectToDelete.id);
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            deleteObject(object) {
                console.log(object);
                this.showModal = true;
                this.objectToDelete = object;
            }
        }
    }
</script>