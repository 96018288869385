<template>
    <div>
        <div class="content-top row">
            <div
                class="col-24 d-flex justify-content-between align-items-center"
            >
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">Lista sklepów</h1>
                    <div class="content-top__subheading">
                        {{ shops.length }}
                        {{
                            shops.length
                                | numeralsText(
                                    "sklep",
                                    "sklepy",
                                    "sklepy"
                                )
                        }}
                    </div>
                </div>
            </div>
        </div>
        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div class="content row">
                <div class="col-24">
                    <table class="items-table items-table--shrink">
                        <thead class="items-table__thead">
                            <tr class="items-table__tr">
                                <th class="items-table__th">
                                    Code
                                </th>
                                <th class="items-table__th text-center">
                                    Name
                                </th>
                                <th class="items-table__th text-center">
                                    Default
                                </th>
                                <th class="items-table__th text-center">
                                    Woo Endpoint
                                </th>
                                <th class="items-table__th p-0 text-right">
                                    Actions
                                </th>
                            </tr>
                        </thead>

                        <tbody class="items-table__tbody">
                            <template v-for="shop in shops">
                                <tr class="items-table__tr" v-bind:key="shop.code">
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Code
                                        </div>
                                        {{ shop.code | uppercase }}
                                    </td>

                                    <td class="items-table__td text-center">
                                        <div class="form-box__mobile-element">
                                            Sklep
                                        </div>
                                        <div v-if="editingObject.key == shop.code">
                                            <input
                                                type="text"
                                                class="items-table__input items-table__input--sm"
                                                v-model="editingObject.name"
                                                @keypress.enter.prevent="
                                                    saveObject()
                                                "
                                            />
                                        </div>
                                        <div v-else>
                                            {{ shop.name | uppercase }}
                                        </div>
                                    </td>

                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">
                                            Default
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <div
                                                :class="[
                                                    'items-table__status',
                                                    {
                                                        'items-table__status--default':
                                                            shop.is_default == '1',
                                                    },
                                                ]"
                                            ></div>
                                        </div>
                                    </td>

                                    <td class="items-table__td text-center">
                                        <div class="form-box__mobile-element">
                                            Woo Endpoint
                                        </div>
                                        <div v-if="editingObject.key == shop.code">
                                            <input
                                                style="min-width: 180px"
                                                type="text"
                                                class="items-table__input items-table__input--sm"
                                                v-model="editingObject.woo_endpoint"
                                                @keypress.enter.prevent="
                                                    saveObject()
                                                "
                                            />
                                        </div>
                                        <div v-else>
                                            {{ shop.woo_endpoint | uppercase }}
                                        </div>
                                    </td>

                                    <td
                                        class="items-table__td items-table__td--button"
                                    >
                                        <div class="form-box__mobile-element">
                                            Actions
                                        </div>
                                        <div class="d-flex justify-content-end">
                                            <button
                                                v-if="
                                                    editingObject.key == shop.code
                                                "
                                                class="button-icon"
                                                @click.prevent="saveObject()"
                                            >
                                                <icon icon="save" />
                                            </button>
                                            <button
                                                v-else
                                                class="button-icon"
                                                @click.prevent="editObject(shop)"
                                            >
                                                <icon icon="edit" />
                                            </button>

                                            <button
                                                class="button-icon button-icon--ml-10"
                                                @click="deleteShop(shop)"
                                                v-if="!shop.is_default"
                                            >
                                                <icon icon="trash" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>

                            <tr class="items-table__tr">
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Code</div>
                                    <input
                                        v-model="newShop.code"
                                        maxlength="2"
                                        class="items-table__input items-table__input--xs"
                                        @keypress.enter.prevent="storeShop()"
                                    />
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Name</div>
                                    <input
                                        v-model="newShop.name"
                                        class="items-table__input items-table__input--sm"
                                        @keypress.enter.prevent="storeShop()"
                                    />
                                </td>
                                <td
                                    class="items-table__td items-table__td--buttom"
                                    colspan="2"
                                >
                                    <div class="form-box__mobile-element"></div>
                                    <div class="d-flex justify-content-end">
                                        <button
                                            class="button button--icon"
                                            @click="storeShop()"
                                        >
                                            <icon
                                                icon="plus"
                                                icon-classes="button__icon button__icon--left icon--white icon__plus--small"
                                            />
                                            <span class="button__text"
                                                >Add new shop</span
                                            >
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                Delete shop
            </div>

            <div slot="body">
                <div>
                    Do you really want to delete
                    <strong>{{ shopToDelete.code | uppercase }}</strong
                    >?
                </div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button
                    class="button button--small button--grey"
                    @click="showModal = false"
                >
                    No
                </button>
                <button
                    class="button button--small button--ml-10"
                    @click="confirmDeleteShop()"
                >
                    Yes
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import axios from "axios";
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import Icon from "./parts/Icon";
import Modal from "./parts/Modal";

export default {
    data() {
        return {
            isLoading: true,
            shops: {},
            showModal: false,
            shopToDelete: false,
            editingObject: {},
            newShop: {
                default: 0,
            },
        };
    },
    components: {
        Icon,
        Modal,
    },
    async created() {
        this.getShops();
    },
    methods: {
        async storeShop(data) {
            if (!data) data = this.newShop;

            try {
                const response = await axios.post(
                    API_BASE_URL + "/shops",
                    data
                );
                this.shops = response.data;
                this.editingObject = {};
                this.newShop = {};
                this.$store.dispatch("shop/fetchShops");
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
            }
        },
        async getShops() {
            this.isLoading = true;
            this.response = {};
            try {
                const response = await axios.get(API_BASE_URL + "/shops");
                this.shops = response.data;
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
            }
            this.isLoading = false;
        },
        async deleteShop(shop) {
            this.showModal = true;
            this.shopToDelete = shop;
        },
        async confirmDeleteShop() {
            this.response = {};
            this.showModal = false;
            try {
                await axios.delete(
                    API_BASE_URL + "/shops/" + this.shopToDelete.code
                );
                this.getShops();
            } catch (error) {
                this.$root.$emit("noticesBox", error.response.data);
            }
        },
        editObject(object) {
            this.editingObject = object;
            this.editingObject.key = object.code;
        },
        async saveObject() {
            this.storeShop(this.editingObject);
        },
    },
};
</script>
