<template>
    <div>
        <quill-editor
            ref="rich_editor"
            v-model="content"
            :options="options"
            :disabled="preview"
            @change="onEditorChange($event)"
        />

        <button v-if="!preview"
            class="button button--small button--grey mt-2"
            @click="showModal = true"
        >
            Edit html
        </button>
        <!-- Modal delete product -->
        <modal :show="showModal" size="xl" @close="showModal = !showModal">
            <div slot="header" class="notice-modal__header--title">
                Edit html
            </div>

            <div slot="body">
                <div>
                    <textarea
                        class="product-form__input product-form__input--textarea"
                        v-model="modalContent"
                    ></textarea>
                </div>
            </div>

            <div slot="footer">
                <button
                    class="button button--small"
                    @click="submitModal()"
                >
                    Save
                </button>
            </div>
        </modal>
    </div>
</template>

<style>
.ql-editor {
    min-height: 160px;
    max-height: 160px;
}
</style>

<script>
import Modal from "./parts/Modal.vue";

export default {
    data() {
        return {
            modalContent: this.inputContent,
            showModal: false,
            content: this.inputContent,
        };
    },
    components: {
        Modal,
    },
    props: {
        inputContent: {
            required: true
        },
        options: {
            type: Object,
            required: false,
            value: []
        },
        preview: Boolean
    },
    methods: {
        onEditorChange({ html }) {
            this.modalContent = html;
            this.$emit("update", html);
        },
        submitModal() {
            this.content = this.modalContent;
            this.showModal = false;
        },
    },
    computed: {
        editor() {
            return this.$refs.rich_editor.quill;
        },
    },
};
</script>
