<template>
    <div v-if="shops">
        <div class="shop-select">
            <label class="shop-select__label">Sklep</label>

            <treeselect
                :id="'shop_select'"
                :multiple="false"
                :options="shops"
                :value="currentShop"
                :clearable="false"
                :searchable="false"
                :reduce="(shop) => category.code"
                :normalizer="shopNormalizer"
                placeholder=""
                @select="switchShop"
            />
        </div>
    </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";

export default {
    computed: {
        currentShop() {
            return this.$store.state.shop.current;
        },
        shops() {
            return this.$store.state.shop.all;
        }
    },
    components: {
        Treeselect,
    },
    mounted() {
        // Zabezpieczenie na wypadek, gdyby w local storage nie było języków
        if (!this.$store.state.shop && !this.$store.state.shop.all) {
            this.$store.dispatch("shop/fetchShops");
        }
    },
    methods: {
        shopNormalizer(shop) {
            return {
                id: shop.code,
                label: `${shop.name}`,
            };
        },
        switchShop(shop) {
            shop.preventReload = false;

            this.$store.dispatch("shop/setShop", shop);
        },
    },
};
</script>
