<template>
    <div class="product-form row" v-if="product.attributes">
        <div class="col-24">
            <div class="product-form__header d-flex align-items-center">
                <!-- <div :class="{ 'is-hidden' : isHidden }" @click.prevent="isHidden = !isHidden">
                    <icon icon="plus" v-if="!isHidden" icon-classes="icon__plus--large icon--black icon--rotated" />
                    <icon icon="plus" v-else icon-classes="icon__plus--large icon--black" />
                </div> -->
                <span class="product-form__header-title">Atrybuty</span>
            </div>
        </div>

        <transition name="transition-slide">
            <div class="col-24 product-form__content product-form__content--double-margin" v-if="product.attributes.length > 0">

            <!-- <div class="col-24 product-form__content product-form__content--double-margin" v-if="!isHidden && attributes.length > 0"> -->
                <table class="items-table">
                    <thead>
                        <tr class="items-table__tr">
                            <th class="items-table__th">nazwa</th>
                            <th class="items-table__th">wartość</th>
                            <th class="items-table__th">jednostka</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="items-table__tr" v-for="(attr) in product.attributes" :key="attr.id">
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Nazwa</div>
                                {{attr.name}}
                            </td>
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Wartość</div>
                                <input v-if="attr.type == 'text'" type="text" class="items-table__input items-table__input--sm" v-model="attr.value" @change="onInputChange()"/>

                                
                                <treeselect v-if="attr.type == 'słownik'"
                                    sort-value-by="INDEX"
                                    :default-expand-level="Infinity"
                                    :value-consists-of="'LEAF_PRIORITY'"
                                    v-model="attr.product_atribute_options"
                                    :multiple="true"
                                    :options="attr.options"
                                    :normalizer="normalizer"
                                />
                                
                                <label v-else-if="attr.type == 'checkbox'" :for="'check-attr-' + attr.id" class="product-form__checkbox">
                                    <input :id="'check-attr-' + attr.id" class="product-form__checkbox--input"
                                        type="checkbox" v-model="attr.value" @change="onInputChange()" true-value="1" false-value="0" />

                                    <span class="product-form__checkbox-checkmark"
                                        v-text="(attr.value == 1) ? 'Tak' : 'Nie'"></span>
                                </label>
                            </td>
                            <td class="items-table__td">
                                <div class="form-box__mobile-element">Jednostka</div>
                                {{ attr.unit ? (attr.type != 'checkbox' ? attr.unit : 'nd.') : (attr.type != 'checkbox' ? '-' : 'nd.') }}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div v-if="!product.attributes.length > 0" class="col-24 product-form__content product-form__content--double-margin">
                <div class="d-flex justify-content-start">
                    <table class="product-form__table">
                        <tr class="product-form__table-row">
                            <td class="product-form__table-data">
                                <span class="product-form__title product-form__title--regular">Brak atrybutów.</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import Treeselect from '@riophae/vue-treeselect';

    export default {
        data() {
            return {
                isHidden: false,
                isLoaded: true,
                isLoading: true,
                normalizer(node) {
                    return {
                        id: node.id,
                        label: "#" + node.id + " " + node.name,
                    };
                },
            }
        },
        components: {
            Treeselect
        },
        props: {
            product: {
                type: Object,
                required: false
            }
        },
        created() {
        },
        mounted() {
            this.$root.$on('saveProduct', () => {
                this.storeProduct();
            });
        },
        beforeDestroy() {
            this.$root.$off('saveProduct');
        },
    }
</script>