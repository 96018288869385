import Vue from 'vue';

Vue.filter('numeralsText', function (value, one, two, three) {
    if (value === 1) {
        return one;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
        return two;
    } else {
        return three;
    }
});