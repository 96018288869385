<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-countries"
        title = "Lista krajów"
        :numeral_text = "['kraj', 'kraje', 'krajów']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>