<template>
    <div>
        <div class="content-top row" v-if="!isDeleted">
            <div class="col-18 content-top__wrap">
                <div class="content-top">
                    <h1 class="content-top__heading">Edytuj produkt</h1>
                    <div class="content-top__subheading ml-0 mt-2" v-if="product.activity">ostatnia zmiana
                        {{ product.activity.updated_at | prettyDate(true) }} przez {{ product.activity.user.email }}
                    </div>
                </div>
            </div>
        </div>

        <div class="product-bar">
            <div class="site-content product-bar__site-content">
                <div class="container-fluid">
                    <div class="product-bar__inner">
                        <div class="product-bar__meta">
                            <h2 class="product-bar__title">
                                #{{ product.sku }} - {{product.name_short}}
                            </h2>
                        </div>
                        <div class="product-bar__inputs">
                            <div class="product-bar__input">
                                <div class="shop-select">
                                    <label class="shop-select__label"
                                        >Sklep</label
                                    >

                                    <treeselect
                                        :id="'shop_select'"
                                        :multiple="false"
                                        :options="product.shops"
                                        :value="currentShop"
                                        :clearable="false"
                                        :searchable="false"
                                        :normalizer="shopNormalizer"
                                        placeholder=""
                                        @select="switchShop"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="product-bar__buttons">
                            <button
                                class="button button--icon"
                                @click.prevent="saveProduct()"
                                :disabled="isDataChanged"
                            >
                                <div
                                    class="loader button__icon button__icon--left"
                                    v-if="isStoring"
                                ></div>
                                <icon
                                    v-else
                                    icon="save"
                                    icon-classes="button__icon button__icon--left icon--white"
                                />
                                <span
                                    class="button__text"
                                    v-text="isStoring ? 'Saving...' : 'Save'"
                                    >Save</span
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div class="content" v-if="!isDeleted">
                <div class="row">
                    <div class="col-24">
                        <tabs>
                            <tab title="Podstawowe">
                                <div class="row">
                                    <div class="col-24">
                                        <BasicInfo
                                        :product="product"
                                        :dictionaries = "dictionaries"
                                        ></BasicInfo>
                                    </div>
                                </div>
                            </tab>
                            <tab title="Pliki">
                                <div class="row">
                                    <div class="col-24">
                                        <Files
                                        :product="product"></Files>
                                    </div>
                                </div>
                            </tab>
                            <tab title="Ecommerce">
                                <div class="row">
                                    <div class="col-24">
                                        <MainPhoto
                                            :product="product"
                                        ></MainPhoto>
                                    </div>

                                    <div class="col-24">
                                        <ProductPhotos
                                            :product="product"
                                        ></ProductPhotos>
                                    </div>

                                    <div class="col-24">
                                        <Ecommerce
                                        :product="product">
                                        </Ecommerce>
                                    </div>
                                </div>
                            </tab>
                            <tab title="Atrybuty">
                                <div class="row">
                                    <div class="col-24">
                                        <Attributes
                                        :product="product"></Attributes>
                                    </div>
                                </div>
                            </tab>
                            <tab title="ERP">
                                <div class="row">
                                    <div class="col-24">
                                        <Erp
                                        :product="product"
                                        :dictionaries = "dictionaries"
                                        :shops="shops"
                                        v-if="product.id"
                                        ></Erp>
                                    </div>
                                </div>
                            </tab>
                            <tab title="WooCommerce">
                                <div class="row">
                                    <div class="col-24">
                                        <Woocommerce
                                        :product="product"></Woocommerce>
                                    </div>
                                </div>
                            </tab>
                            <tab title="Admin">
                                <div class="row">
                                    <div class="col-24">
                                        <Admin
                                        :product="product">
                                        </Admin>
                                    </div>
                                </div>
                            </tab>
                        </tabs>
                    </div>
            </div>

            <div v-if="isDeleted">
                Trwa usuwanie produktu... Za 2 sekundy, zostaniesz przekierowany na listę produktów.
            </div>
        </div>

        <!-- Modal delete product -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie produktu
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć produkt
                    <strong>{{ productToDelete.index + ' - ' + productToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteProduct()">
                    Tak
                </button>
            </div>
        </modal>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './parts/Icon';
    import Modal from './parts/Modal';
    import jQuery from 'jquery';
    import Treeselect from '@riophae/vue-treeselect';
    import Tabs from "./parts/Tabs.vue";
    import Tab from "./parts/Tab.vue";
    import BasicInfo from "./product-modules/BasicInfo.vue";
    import Ecommerce from "./product-modules/Ecommerce.vue";
    import Erp from "./product-modules/Erp.vue";
    import Admin from "./product-modules/Admin.vue";
    import ProductPhotos from "./product-modules/ProductPhotos.vue";
    import Files from "./product-modules/Files.vue";
    import Woocommerce from "./product-modules/Woocommerce.vue";
    import Attributes from "./product-modules/Attributes.vue";
    import MainPhoto  from "./product-modules/ProductMainPhoto.vue";
    import _ from "lodash";

    export default {
        data() {
            return {
                dictionaries: {},
                isLoading: true,
                product: {},
                isStoring: false,
                productToDelete: {},
                showModal: false,
                isDeleted: false,
                selectedShop: {
                    shop_code: this.$store.state.shop.current
                },
                shopNormalizer(node) {
                    return {
                        id: node.code,
                        label: node.name,
                    };
                },
                brands: [],
                categories: [],
                collections: [],
                materials: [],
                styles: [],
                rooms: [],
                colors: [],
                stock_statuses: [],
                producers: [],
                countries: [],
                seasons: [],
                shipmentSizes: [],
            }
        },
        components: {
            Icon,
            Modal,
            Treeselect,
            Tabs,
            Tab,
            BasicInfo,
            Ecommerce,
            Erp,
            Admin,
            ProductPhotos,
            Files,
            Woocommerce,
            Attributes,
            MainPhoto
        },
        computed: {
            currentShop() {
                return this.$store.state.shop.current;
            },
            shops() {
                return this.$store.state.shop.all;
            },
            isDataChanged: function() {
                return _.isEqual(this.product, this.product_old);
            }
        },
        created() {
            this.getProduct();
            this.getDictionaries();
        },
        mounted() {

            let boxShop = jQuery('.js-products-shops'),
                sidebar = jQuery('.sidebar');

            if (boxShop.length != 0) {
                let topBoxShop = boxShop.offset().top + 125;

                var scroll = jQuery(window).scrollTop();
                if (scroll > topBoxShop) {
                    sidebar.addClass('sidebar--open');
                } else {
                    sidebar.removeClass('sidebar--open');
                }

                jQuery(window).scroll(function () {
                    var scroll = jQuery(window).scrollTop();

                    if (scroll > topBoxShop) {
                        sidebar.addClass('sidebar--open');
                    } else {
                        sidebar.removeClass('sidebar--open');
                    }
                });
            }

        },
        beforeDestroy() {
            this.$root.$off('addProductModalSuccess');
        },
        methods: {
            getDictionaries() {
                axios.get(API_BASE_URL + '/dictionaries')
                    .then((response) => {
                        this.dictionaries = response.data;
                    })
                    .catch(error => {
                        this.$root.$emit('noticesBox', error.response.data);
                    });
            },
            async getProduct() {
                this.isLoading = true;
                let vm = this;

                try {
                    var params = {
                        'shop_code': this.$route.params.shop
                    };
                    const response = await axios.get(API_BASE_URL + '/products/' + vm.$route.params.id, {
                        params: params
                    });
                    vm.product = response.data;

                    //TODO - przerobić
                    let colors_child = [];
                    vm.product.colors_child.forEach((obj) => {
                        const pushed = Number.isInteger(obj) ? obj : obj.id;
                        colors_child.push(pushed);
                    });
                    vm.product.colors_child = colors_child;

                    let materials_child = [];
                    vm.product.materials_child.forEach((obj) => {
                        const pushed = Number.isInteger(obj) ? obj : obj.id;
                        materials_child.push(pushed);
                    });
                    vm.product.materials_child = materials_child;

                    let rooms_ids = [];
                    vm.product.rooms.forEach((obj) => {
                        const pushed = Number.isInteger(obj) ? obj : obj.id;
                        rooms_ids.push(pushed);
                    });
                    vm.product.rooms = rooms_ids;

                    let category_ids = [];
                    vm.product.categories.forEach((obj) => {
                        const pushed = Number.isInteger(obj) ? obj : obj.id;
                        category_ids.push(pushed);
                    });
                    vm.product.categories = category_ids;

                    vm.product.attributes.forEach(attribute => {
                        let options_ids = [];
                        attribute.product_atribute_options.forEach((obj) => {
                            const pushed = Number.isInteger(obj) ? obj : obj.id;
                            options_ids.push(pushed);
                        });
                        attribute.product_atribute_options = options_ids;                        
                    });



                    vm.product_old = _.cloneDeep(vm.product)
                } catch(error){
                    vm.$root.$emit('noticesBox', error.response.data);
                }
                this.isLoading = false;

            },
            saveProduct() {
                this.isStoring = true;
                this.storeProduct();
            },
            goToOrAdd(shop, type) {
                let vm = this;
                if (type == 'addTranslation') {

                    vm.$root.$emit('addProductModal', {
                        translation: shop.shop_code,
                        addTranslationTo: vm.$route.params.id
                    });

                } else if (type == 'productEdit') {
                    vm.$store.dispatch('shop/setShop', {
                        code: shop.shop_code,
                        preventReload: true
                    }).then(
                        function () {
                            vm.$router.push({
                                name: 'productEdit',
                                params: {
                                    id: shop.product_id
                                }
                            });
                        }
                    );
                }
            },
            handleSelectShop(node) {
                this.selectedShop = {
                    shop_code: this.$store.state.shop.current
                };

                if (node.product_id && node.product_id != this.product.id) {
                    this.goToOrAdd(node, 'productEdit');
                } else {
                    this.goToOrAdd(node, 'addTranslation');
                }

            },
            async deleteProduct(product) {
                this.showModal = true;
                this.productToDelete = product;
            },
            confirmDeleteProduct() {
                let vm = this;
                this.showModal = false;
                axios.delete(API_BASE_URL + '/products/' + vm.productToDelete.id).then(() => {

                    vm.isDeleted = setTimeout(() => {
                        vm.$router.push({
                            name: 'productsList'
                        });
                    }, 2000);

                });
            },
            async storeProduct() {
                const config = {
                    headers: {
                        "content-type": "application/json;charset=utf-8",
                    },
                };

                const data = this.product;
                axios
                    .put(
                        API_BASE_URL + `/products/${this.product.id}`,
                        JSON.stringify(data),
                        config
                    )
                    .then(() => {
                        this.$root.$emit("noticesBox", {
                            type: "success",
                            message: "Successfully saved basic product data.",
                        });
                        this.getProduct();
                        this.isStoring = false;
                    })
                    .catch((error) => {
                        if (error.response.status == 422) {
                            this.$emit("validators", error.response.data.errors);
                        }
                        this.isStoring = false;
                        this.$root.$emit("noticesBox", error.response.data);
                    });
            },
            switchShop(shop) {
                this.switchLocale(shop.code);
            },
            switchLocale(shop_code) {
                this.$router.push({
                    name: "productEdit",
                    params: {
                        sku: this.product.sku,
                        shop: shop_code,
                    },
                });
            },
        }
    }
</script>