<template>
    <div>

        <div class="content-top row">
            <div class="col-24 d-flex justify-content-between align-items-center">
                <div class="content-top__title-box">
                    <h1 class="content-top__heading">{{ title }}</h1>
                    <div class="content-top__subheading">{{ objects.length }}
                        {{ objects.length | numeralsText(numeral_text[0], numeral_text[1], numeral_text[2]) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="spim-loader-container" :class="{ 'is-loading': isLoading }">
            <div class="spim-loader">
                <div class="spim-loader__rhombus-1"></div>
                <div class="spim-loader__rhombus-2"></div>
                <div class="spim-loader__rhombus-3"></div>
                <div class="spim-loader__rhombus-4"></div>
            </div>
            <div class="content row">
                <div class="col-24">
                    <table class="items-table items-table--shrink">
                        <thead class="items-table__thead">
                            <tr class="items-table__tr">
                                <th class="items-table__th">
                                    ID
                                </th>
                                <th class="items-table__th">
                                    Nazwa
                                </th>
                                <th class="items-table__th p-0 text-right">
                                    Akcje
                                </th>
                            </tr>
                        </thead>

                        <tbody class="items-table__tbody">
                            <template v-for="object in objects">
                                <tr class="items-table__tr" v-bind:key="'object_' + object.id">
                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Id</div>
                                        {{ object.id }}
                                    </td>

                                    <td class="items-table__td">
                                        <div class="form-box__mobile-element">Nazwa</div>
                                        <div v-if="editingObject.key == object.id">
                                            <input type="text" v-model="editingObject.name" @keypress.enter.prevent="saveObject()"/>
                                        </div>
                                        <div v-else>
                                            {{ object.name }}
                                        </div>
                                    </td>

                                    <td class="items-table__td items-table__td--button">
                                        <div class="form-box__mobile-element">Akcje</div>
                                        <div class="d-flex justify-content-end">
                                            <button v-if="editingObject.key == object.id" class="button-icon"
                                                @click.prevent="saveObject()">
                                                <icon icon="save" />
                                            </button>
                                            <button v-else class="button-icon" @click.prevent="editObject(object)">
                                                <icon icon="edit" />
                                            </button>

                                            <button class="button-icon button-icon--ml-10" @click="deleteObject(object)">
                                                <icon icon="trash" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <tr>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element"></div>
                                    #
                                </td>
                                <td class="items-table__td">
                                    <div class="form-box__mobile-element">Nazwa</div>
                                    <input v-model="newObject.name" class="items-table__input" @keypress.enter.prevent="storeObject()"/>
                                </td>
                                <td class="items-table__td items-table__td--button">
                                    <div class="form-box__mobile-element"></div>
                                    <div class="d-flex justify-content-end">
                                        <button class="button button--icon" @click="storeObject()">
                                            <icon icon="plus"
                                                icon-classes="button__icon button__icon--left icon--white icon__plus--small" />
                                            <span class="button__text">Dodaj</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Modal delete -->
        <modal :show="showModal" size="md" @close="showModal = !showModal;">
            <div slot="header" class="notice-modal__header--title">
                Usuwanie stawki vat
            </div>

            <div slot="body">
                <div>Czy napewno chcesz usunąć stawkę vat <strong>{{ '#' + objectToDelete.id + ' - ' + objectToDelete.name }}</strong>?</div>
            </div>

            <div slot="footer" class="d-flex align-items-center">
                <button class="button button--small button--grey" @click="showModal = false">
                    Nie
                </button>
                <button class="button button--small button--ml-10" @click="confirmDeleteObject()">
                    Tak
                </button>
            </div>
        </modal>

    </div>
</template>

<script>
    import axios from 'axios';
    const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
    import Icon from './../parts/Icon';
    import Modal from './../parts/Modal';

    export default {
        data() {
            return {
                isLoading: true,
                objects: {},
                newObject: {},
                editingObject: {},
                showModal: false,
                objectToDelete: {}
            }
        },
        props:{
            dictionary_link: String,
            title: String,
            numeral_text: Array,
            columns_title: Array
        },
        async created() {
            this.getObjects();
        },
        components: {
            Icon,
            Modal
        },
        methods: {
            async getObjects() {
                this.isLoading = true;
                try {
                    const response = await axios.get(API_BASE_URL + this.dictionary_link);
                    this.objects = response.data;
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
                this.isLoading = false;
            },
            async storeObject(data = false) {
                if (!data) data = this.newObject;
                if (!data.shop_code) data.shop_code = this.$i18n.locale;

                try {
                    const response = await axios.post(API_BASE_URL + this.dictionary_link, data);
                    this.newObject = {};
                    this.objects = response.data;
                    this.editingObject = {};
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            editObject(object) {
                this.editingObject = object;
                this.editingObject.key = object.id;
            },
            saveObject() {
                this.storeObject(this.editingObject);
            },
            async confirmDeleteObject() {
                this.showModal = false;
                try {
                    await axios.delete(API_BASE_URL + this.dictionary_link + '/' + this.objectToDelete.id);
                    this.getObjects();
                } catch (error) {
                    this.$root.$emit('noticesBox', error.response.data);
                }
            },
            deleteObject(object) {
                this.showModal = true;
                this.objectToDelete = object;
            }
        }
    }
</script>