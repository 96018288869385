<template>
    <div>
        <dictionary-simple-table
        dictionary_link = "/product-vats"
        title = "Lista stawek vat"
        :numeral_text = "['stawka vat', 'stawki vat', 'stawek vat']"
        :columns_title = "['name']"
        ></dictionary-simple-table>
    </div>
</template>

<script>
    import DictionarySimpleTable from './DictionarySimpleTable.vue';
    export default {
        components: {
            DictionarySimpleTable
        },
    }
</script>